<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotTitle="{ row }">
        <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.id}`,
                state: { tabName: row.id },
              }"
              >{{ row.title }}</router-link
            >
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import editModal from "../components/editModal";
import _ from "lodash";
import { apiDictListByCode } from "@/api/common";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initFilterOption();
  initTableData();
  initOptions();
});

const messageTypeMap = ref({});
const messageStatus = ref({});
const initOptions = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageTypeMap.value = _map;
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};

const filterSource = ref([
  {
    label: "所属排放范围",
    type: "select",
    bindKey: "rangeId",
    fieldNames:{
      'value':'id',
      'label':'range'
    },
    options: [],
  },
  {
    label: "排放类别",
    type: "input",
    bindKey: "classification",
    placeholder: "请输入",
  },
]);

const initFilterOption = async () => {
  let { result } = await apiCarbonInfo.typeList();
  let { result:result2 } = await apiCarbonInfo.rangeList();

  const _list = _.cloneDeep(filterSource.value);
  _list[0].options = result2 || [];
  _list[1].options = result || [];

  filterSource.value = _list;
};
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "bizId",
      width: 120,
    },
    {
      title: "排放子类",
      type: "text",
      key: "subClassification",
    },
    {
      title: "所属排放类别",
      type: "text",
      key: "classification",
    },
    {
      title: "所属排放范围",
      type: "text",
      key: "range",
    },
  ],
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiCarbonInfo.subTypePage(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);


const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  20px 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>

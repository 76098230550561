<template>
  <BasicModal
    title="正常用量范围设置"
    width="400px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 9 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
        @change="formChange"
      >
    </BasicForm>
      
    </div>
    <div class="actions">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="ok"
        >保存</a-button
      >
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiCarbonInfo } from "@/api/carbon.js";
import dayjs from "dayjs";


const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  id: Number,
  year: Number,
  info: {
    type: Object,
    default: null,
  }
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);


onMounted(async () => {
  if (props.info) {
    formData1.emissionLowerLimit = props.info.emissionLowerLimit
    formData1.emissionUpperLimit = props.info.emissionUpperLimit
  }
});


const formItemsMap1 = reactive({
  emissionLowerLimit: {
    label: "排放下限",
    prop: "emissionLowerLimit",
    type: "inputNumber",
    required: true,
    span: 18,
    max:100,
    min:0,
    after:'%',
    requiredMessage: "请输入",
  },
  emissionUpperLimit: {
    label: "排放上限",
    prop: "emissionUpperLimit",
    type: "inputNumber",
    required: true,
    max:100,
    min:0,
    after:'%',
    span: 18,
  },
});
const formData1 = reactive({
  emissionLowerLimit:'',
  emissionUpperLimit: "",
});

const confirmLoading = ref(false)
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      id: props.info.id,
      subjectId: props.id,
      year:  dayjs(props.year).format('YYYY') ,
      emissionLowerLimit: formData1.emissionLowerLimit,
      emissionUpperLimit: formData1.emissionUpperLimit,
    };
    await apiCarbonInfo.limitUpdate(params);
    message.success(`保存成功`);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;
  button {
    margin-left: 10px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>

<template>
  <BasicModal
    :title="info && info.id ? '编辑文章' : '创建文章'"
    width="600px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
    </div>
    <div class="actions">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="ok"
        >保存</a-button
      >
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiDictListByCode } from "@/api/common";
import { apiCarbonInfo } from "@/api/carbon.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);


onMounted(async () => {
  await initFormItems();
  if (props.info) {
    initDetail();
  }
  formItemsMap1.user.disabled = true;
});

const initDetail = () => {
 for(let key in formData1){
  formData1[key] = props.info[key]
 }
};

const formItemsMap1 = reactive({
  title: {
    label: "文章标题",
    prop: "title",
    type: "textarea",
    span: 18,
  },
  contentType: {
    label: "内容分类",
    prop: "contentType",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  articleType: {
    label: "文章类型",
    prop: "articleType",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },

});
const formData1 = reactive({
  title: '',
  contentType: "",
  articleType: "",
});

const initFormItems = async () => {
  apiDictListByCode("ARTICLE_TYPE").then((res) => {
    formItemsMap1.articleType.options = res.result || [];
  });

  apiDictListByCode("CARBON_CONTENT_TYPE").then((res) => {
    formItemsMap1.contentType.options = res.result || [];
  });

};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      title: formData1.title,
      contentType: formData1.contentType,
      articleType: formData1.articleType,
    };
    if (props.info) {
      params.id = props.info.id;
    }
    await apiCarbonInfo.add(params);
    message.success(`${params.id ? "编辑成功" : "保存成功"}！`);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;
  button {
    margin-left: 10px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>

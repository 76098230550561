<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button v-show="currentTab == 2" type="primary" @click="handleAdd">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotTitle="{ row }">
        <router-link
              :to="{
                path: `/carbon/information/manager/edit/${row.id}`,
                state: { tabName: `碳咨讯维护-${row.id}` },
              }"
              >{{ row.title }}</router-link
            >
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onActivated } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import editModal from "./components/editModal";
import _ from "lodash";
import { Modal, message } from "ant-design-vue";
import { apiDictListByCode } from "@/api/common";


onActivated(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initFilterOption();
  initTableData();
  initOptions();
});


const currentTab = ref('2');
const messageTypeMap = ref({});
const messageStatus = ref({});
const initOptions = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageTypeMap.value = _map;
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};

const filterSource = ref([
  {
    label: "内容分类",
    type: "select",
    bindKey: "contentType",
    options: [],
  },
  {
    label: "发布状态",
    type: "select",
    bindKey: "articleStatus",
    options: [],
  },
  {
    label: "文章标题关键词",
    type: "input",
    bindKey: "title",
    placeholder: "请输入",
  },
]);

const initFilterOption = async () => {
  let { result } = await apiDictListByCode("CARBON_CONTENT_TYPE");
  let { result:result2 } = await apiDictListByCode("MESSAGE_STATUS");

  const _list = _.cloneDeep(filterSource.value);
  _list[0].options = result || [];
  _list[1].options = result2 || [];

  filterSource.value = _list;
};
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "id",
      width: 120,
    },
    {
      title: "文章标题",
      type: "customize",
      key: "title",
      slotName: "slotTitle",
    },
    {
      title: "内容分类",
      type: "text",
      key: "contentTypeName",
    },
    {
      title: "文章类型",
      type: "text",
      key: "articleTypeName",
    },
    {
      title: "创建人",
      type: "text",
      key: "createUserName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "发布状态",
      type: "text",
      key: "articleStatus",
      width: 100,
      formatter(val) {
        return val === '01'?'草稿':'已发布';
      },
    },
  ],
  action: {
    title: "操作",
    width: 120,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "SendOutlined",
        iconTip: "取消发布",
        visibleFn(row) {
          return row.articleStatus == "02";
        },
        onClick: (row) => {
          handleCancelPublish(row);
        },
      },
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        visibleFn(row) {
          return row.articleStatus == "01";
        },
        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDel(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiCarbonInfo.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);
const handleAdd = () => {
  addModalVisible.value = true;
  currentRow.value = null;
};

const handleDel = async ({ id }) => {
  Modal.confirm({
    title: "提醒",
    content: "请确认是否删除？",
    async onOk() {
      await apiCarbonInfo.delete({id});
      message.success("删除成功");
      initTableData();
    },
  });
};

const handleEdit = (row) => {
  addModalVisible.value = true;
  console.log(234, row);
  currentRow.value = row;
};
const handleCancelPublish = (row)=>{
  apiCarbonInfo.cancelPublish({
        id: row.id
    }).then(()=>{
        message.success('已取消发布！');
        initTableData();
    })
}

const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  20px 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>

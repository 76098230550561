<template>
  <div class="ConfigGas">
    <div class="useWater">
      <h1>全部用气量</h1>
      <div class="content">
        <label>计量设备：</label>
        <ul>
          <li v-for="(item, index) in deviceList" :key="index">
            <a-select
              style="width: 146px"
              v-model:value="item.bizDeviceId"
              @change="(value, option) => deviceChange(option, item)"
            >
              <a-select-option
                v-for="item in deviceOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-select
              style="width: 146px"
              v-model:value="item.computerTag"
              @change="(value, option) => computerChange(option, item)"
            >
              <a-select-option
                v-for="item in computerTagOptions"
                :key="item.id"
                :value="item.value"
                :label="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <section>
              <delete-outlined
                style="color: #696f8c"
                @click="deleteDeviceConfig(index)"
              />
            </section>
          </li>
          <li class="addMore">
            <a-button type="dashed" block @click="addDeviceConfig">
              <template #icon>
                <plus-outlined />
              </template>
              添加
            </a-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="usePrice">
      <h1>用气价格</h1>
      <div class="content">
        <section>
          <label>收费模式：</label>
          <a-select v-model:value="priceConfigParam.chargingMode">
            <a-select-option
              v-for="item in chargingModeOptions"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </section>
        <section>
          <label>用气价格：</label>
          <a-input type="number" v-model:value="priceConfigParam.price">
            <template #suffix> 元/m³ </template>
          </a-input>
        </section>
        <a-button type="primary" @click="save">保存</a-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, unref, defineProps } from "vue";
import { apiDeviceList } from "@/api/centerMonitor/deviceReport.js";
import { apiConfigGas } from "@/api/configuration.js";
import { apiDict } from "@/api/dict.js";
import { message } from "ant-design-vue";
import { cloneDeep } from "lodash";
const props = defineProps({
  selectedProject: {
    type: String,
    default: "",
  },
});

const chargingModeOptions = ref([]);
const computerTagOptions = ref([]);
const deviceOptions = ref([]);
const initSelectData = () => {
  apiDict.selective("CHARGING_MODE").then((res) => {
    chargingModeOptions.value = res.result || [];
  });
  apiDict.selective("COMPUTER_TAG").then((res) => {
    computerTagOptions.value = res.result || [];
  });
  apiDeviceList({ bizCategoryId: "PC0002", bizProjectId: props.selectedProject}).then((res) => {
    deviceOptions.value = res.result || [];
  });
};

const baseConfig = {
  bizDeviceId: "",
  deviceName: "",
  computerTag: "",
  computerTagDesc: "",
};
const deviceList = ref([
  {
    ...cloneDeep(baseConfig),
  },
]);
const addDeviceConfig = () => {
  deviceList.value.push(cloneDeep(baseConfig));
};
const deleteDeviceConfig = (index) => {
  if (deviceList.value.length === 1) return;
  deviceList.value.splice(index, 1);
};

const deviceChange = (option, item) => {
  item.deviceName = option.label;
};
const computerChange = (option, item) => {
  item.computerTagDesc = option.label;
};

const priceConfigParam = reactive({
  price: "",
  chargingMode: "",
});
const detailId = ref(null);
const save = () => {
  if (!props.selectedProject) {
    message.warning("请选择项目");
    return;
  }
  let param = {
    id: detailId.value,
    projectId: props.selectedProject,
    deviceList: [...unref(deviceList)],
    ...priceConfigParam,
  };
  console.log(123, param);
  //return;
  apiConfigGas.add(param).then(() => {
    message.success("保存成功");
  });
};

const initDetail = () => {
  if (!props.selectedProject) return;
  apiConfigGas.detail({ bizProjectId: props.selectedProject }).then((res) => {
    let info = res.result;
    if (info && Array.isArray(info.deviceList) && info.deviceList.length > 0) {
      deviceList.value = res.result.deviceList;
    } else {
      deviceList.value = [baseConfig];
    }
    if (info) {
      detailId.value = info.id;
      priceConfigParam.chargingMode = info.chargingMode + "";
      priceConfigParam.price = info.price;
    } else {
      detailId.value = "";
      priceConfigParam.chargingMode = "";
      priceConfigParam.price = "";
    }
  });
};

onMounted(() => {
  initDetail()
  initSelectData();
});
</script>

<style lang="less" scoped>
.ConfigGas {
  padding-top: 22px;
}

h1 {
  position: relative;
  font-size: 16px;
  line-height: 16px;
  padding-left: 10px;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 16px;
    left: 0;
    top: 1px;
    background: #0256ff;
  }
}

.addMore {
  button {
    background: #ecf4ff;
    color: #0256ff;
    border-color: #7ba3ff;
    font-size: 12px;
  }
}

.useWater {
  margin-bottom: 16px;

  .content {
    display: flex;
    padding-left: 16px;
  }

  li {
    display: flex;
    margin-bottom: 16px;

    section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: 1px solid #d9d9d9;
    }

    .ant-select {
      margin-right: 8px;
    }
  }
}

.usePrice {
  .content {
    padding-left: 16px;

    section {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .ant-select,
      .ant-input-affix-wrapper,
      .ant-input {
        width: 300px;
      }
    }
  }
}
</style>

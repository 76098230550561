import http from "../utils/http";

export function apiUserPage(params) {
  return http.request({
    url: "/user/page",
    method: "get",
    params,
  });
}

/*
 * 登录
 * @param {*} params
 * @returns
 */
export function apiLogin(data, key) {
  return http.request({
    url: "/oauth/auth/login",
    method: "post",
    headers:{
      "Captcha-Key": key
    },
    data,
  });
}

/**
 * 验证码
 * @param {*} data 
 * @returns 
 */
export function apiCaptcha() {
  return http.request({
    url: "/oauth/auth/captcha",
    method: "get",
  });
}

/**
 * 登出
 * @param {*} params
 * @returns
 */
export function apiLoginOut() {
  return http.request({
    url: "/oauth/auth/logout",
    method: "delete",
  });
}

/**
 * 菜单
 * @param {*} params
 * @returns
 */
export function apiSystemMenus(params) {
  return http.request({
    url: "/oauth/auth/module-menus",
    method: "get",
    params,
  });
}
// 文件上传
export const apiFileUpload = {
  image(data) {
    return http.request({
      url: "/cemp-file/file/upload",
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      params: { fileType: "IMAGE" },
      data,
    });
  },
  file(data) {
    return http.request({
      url: "/cemp-file/file/upload",
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      params: { fileType: "FILE" },
      data,
    });
  },
  down(path) {
    return http.request({
      url: `${path}`,
      method: "get",
      responseType: "blob",
    });
  },
};

export function apiGetImage(url) {
  return http.request({
    url,
    method: "get",
    responseType: "blob",
  });
}

/**
 * 获取当前用户管理节点项目树
 * @param {*} params
 * @returns
 */
export function apiUserProjectTree(params) {
  return http.request({
    url: "/cemp-bms/project/current-user/tree",
    method: "get",
    params,
  });
}

export function apiResetPassword(data) {
  return http.request({
    url: "/cemp-oauth/user/password/change",
    method: "put",
    data,
  });
}

export function apiWeather(id) {
  return http.request({
    url: `/cemp-monitor/weather/get-by-project/${id}`,
    method: "get",
  });
}

// 根据code获取字典
export function apiDictListByCode(dictTypeCode) {
  return http.request({
    url: `/cemp-bms/dict/data/selective`,
    method: "get",
    params: {
      dictTypeCode,
    },
  });
}
// 省市区
export function apiAddressList() {
  return http.request({
    url: `/cemp-bms/address/list`,
    method: "get",
  });
}
// 文件上传
export function apiUploadFile(data, fileType = "IMAGE") {
  return http.request({
    url: `/cemp-file/file/upload`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    params: { fileType },
    data,
  });
}

// 忘记密码-发送口令
export function apiForgotCode(account) {
  return http.request({
    url: `/oauth/user/password/forgot/code`,
    method: "post",
    data: {
      account,
    },
  });
}
// 忘记密码
export function apiForgot(data) {
  return http.request({
    url: `/oauth/user/password/forgot`,
    method: "post",
    data,
  });
}


// 拖拉设置 table col 宽度
export function apiColWidthSet(data) {
  return http.request({
    url: `/cemp-monitor/device-monitor/table/label/width`,
    method: "put",
    data,
  });
}


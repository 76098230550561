<template>
  <div class="project-list">
    <div class="category">
      <ModuleList
        v-model:selectValue="selectTypeCode"
        :list="typeList"
        @change="changeModule"
      />
    </div>
    <div class="content">
      <div class="project-list-header">
        <CategorySearch :source="filterSource" @change="search" />
      </div>
      <div class="list" v-if="pageInfo&&pageInfo.length>0">
        <ul class="info-wrapper">
          <li v-for="item in pageInfo" :key="item.id">
            <router-link
              :to="{
                path: `/carbon/information/content/${item.id}`,
                state: { tabName: `碳咨讯-${item.id}` },
              }"
              >{{ item.title }}</router-link
            >
            
            <span class="time">{{ dayjs(item.publishTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
          </li>
        </ul>
        <a-pagination class="pagination" v-model:current="pageNum" :total="pageTotal" @change="dealChange"/>
      </div>
      <div class="empty" v-else>
        <a-empty :image="Empty.PRESENTED_IMAGE_SIMPLE" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onActivated } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { apiCarbonInfo } from "@/api/carbon.js";
import ModuleList from './components/moduleLeft.vue'
import dayjs from "dayjs";
import { apiDictListByCode } from "@/api/common";
import { Empty } from "ant-design-vue";

onActivated(() => {
  initTableData();
  initOptions();
});

const messageStatus = ref({});
let selectTypeCode = ref('');
const typeList = ref([]);
const initOptions = async () => {
  apiCarbonInfo.viewTypeTree().then((res) => {
    if(res.result){
      //typeList.value = convertToTree(res.result)
      typeList.value = res.result;
      if(res.result[0]){
        selectTypeCode = res.result[0].code;
      }
    }
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};
// const convertToTree = (flatArray)=>{
//   const tree = {};
//   flatArray.forEach(item=>{
//     const {id, parentId,code, ...data} = item;
//     if(code == '00' || id === 0){
//       tree[id] = {id: `${id}-${code}`, ...data, children:[]}
//     }else{
//       const parent = tree[parentId];
//       if(parent){
//         parent.children.push({id: `${id}-${code}`, ...data, children:[]});
//       }
//     }
//   });
//   return Object.values(tree);
// }

const filterSource = ref([
  {
    label: "文章标题关键词",
    type: "input",
    bindKey: "title",
    placeholder: "请输入",
  },
]);

let searchData = {};
const search = (data) => {
  searchData = data;
  initTableData();
};

const pageNum = ref(1);
const pageInfo = ref([]);
const pageTotal = ref(0);
const initTableData = async () => {
  const params = {
    pageNo: pageNum.value,
    pageSize: 10,
    contentType: articleType.value,
    ...searchData,
  };
  try {
    const res = await apiCarbonInfo.viewPage(params);
    const { records, total } = res?.result ?? {};
    pageInfo.value = records
    pageTotal.value = total
  } catch (error) {
    console.log(error);
  }
};
const dealChange = (val)=>{
  pageNum.value = val;
  initTableData();
}

const articleType = ref('');
const changeModule = (code)=>{
  console.log(222, code)
  if(code){
    let typeCode = code;
    if(typeCode == '00'){
      articleType.value = ''
    }else{
      articleType.value = typeCode
    }
    initTableData();
  }
  
}


</script>
<style lang="less" scoped>
.info-wrapper{
  border-bottom: 1px solid #e5e6e8;
  li{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 14px;
    border-top: 1px solid #e5e6e8;
    cursor: pointer;
    font-size: 14px;
    &::before{
      content: '';
      position: absolute;
      left: 2px;
      top: 20px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: #333;
    }
  }
}
.project-list {
  display: flex;
  height: 100%;
  gap: 8px;
  .category{
    width: 240px;
    height: 100%;
    background: #fff;
    h2{
      text-align: center;
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #e5e6e8;
      font-size: 14px;
    }
  }
  .content{
    position: relative;
    padding: 10px 20px;
    flex: 1;
    background: #fff;
    .list{
     
      .pagination{
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
.empty{
  margin-top: 150px;
}
</style>

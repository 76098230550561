<template>
  <BasicModal
    :title="type === 'edit' ? '修改MQTT网关' : '新增MQTT网关'"
    width="950px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
      <div>
        <a-form
          ref="formRef2"
          :model="formData2"
          name="basic"
          layout="horizontal"
          autocomplete="off"
          class="form2"
        >
          <a-form-item label="上行主题" name="oldpassword">
            <section v-for="(item, index) in formData2.up" :key="index">
              <a-input v-model:value="item.name" placeholder="输入主题名称" />
              <a-input
                :key="index"
                v-model:value="item.topic"
                style="margin-left: 8px"
                placeholder="输入主题，支持通配"
              />
              <a-button @click="handleDel(1, index)">
                <DeleteOutlined />
              </a-button>
            </section>
            <a-button type="dashed" class="bt-add" @click="handleAdd(1)"
              ><PlusOutlined />添加</a-button
            >
          </a-form-item>
          <a-form-item label="下行主题" name="oldpassword">
            <section v-for="(item, index) in formData2.down" :key="index">
              <a-input v-model:value="item.name" placeholder="输入主题名称" />
              <a-input
                :key="index"
                v-model:value="item.topic"
                style="margin-left: 8px"
                placeholder="输入主题，支持通配"
              />
              <a-button @click="handleDel(2, index)">
                <DeleteOutlined />
              </a-button>
            </section>
            <a-button type="dashed" class="bt-add" @click="handleAdd(2)"
              ><PlusOutlined />添加</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiGateWay } from "@/api/gateway.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: "add",
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);
const formRef2 = ref(null);

onMounted(() => {
  initFormItems();

  if (props.type === "edit") {
    initFormData();
  }
});

const formItemsMap1 = reactive({
  name: {
    label: "网关名称",
    prop: "name",
    type: "input",
    required: true,
    span: 11,
    requiredMessage: "请输入",
  },
  project: {
    label: "接入项目",
    prop: "projectBizId",
    type: "select",
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    disabled: props.type === "edit" ? true : false,
    required: true,
    options: [],
    span: 11,
    requiredMessage: "请选择",
  },
  product: {
    label: "接入产品",
    prop: "productBizIds",
    type: "select",
    disabled: props.type === "edit" ? true : false,
    fieldNames: {
      label: "name",
      value: "bizId",
    },
    maxTagCount: 1,
    mode: "multiple",
    options: [],
    required: true,
    span: 11,
    requiredMessage: "请选择",
  },
  area: {
    label: "网关描述",
    prop: "description",
    type: "input",
    span: 11,
  },
  serverName: {
    label: "运行服务器",
    prop: "serverId",
    type: "select",
    fieldNames: {
      label: "label",
      value: "serverId",
    },
    required: true,
    options: [],
    span: 11,
    requiredMessage: "请选择",
  },
  area2: {
    label: "client ID",
    prop: "clientId",
    type: "input",
    span: 11,
  },
  code3: {
    label: "用户名",
    prop: "username",
    type: "input",
    required: true,
    span: 11,
    requiredMessage: "请输入",
  },
  code4: {
    label: "密码",
    prop: "password",
    type: "input",
    required: true,
    span: 11,
    requiredMessage: "请输入",
  },
});
const initFormItems = async () => {
  apiGateWay.getProducts().then((res) => {
    formItemsMap1.product.options = res.result;
  });

  apiGateWay.getProject().then((res) => {
    formItemsMap1.project.options = res.result;
  });

  apiGateWay.getService().then((res) => {
    res.result&&res.result.forEach(item=>{
      item.label = `${item.serverName}( cpu: ${item.cpuUsed}% ; free men: ${item.memoryAvailable}G )`
    })
    formItemsMap1.serverName.options = res.result;
  });
};
const formData1 = reactive({
  name: "",
  projectBizId: "",
  productBizIds: [],
  description: "",
  clientId: "",
  username: "",
  password: "",
  serverId: ""
});
const formData2 = reactive({
  up: [{ name: "", topic: "" }],
  down: [{ name: "", topic: "" }],
});
const handleAdd = (type) => {
  if (type === 1) {
    //up
    formData2.up.push({ name: "", topic: "" });
  } else {
    //down
    formData2.down.push({ name: "", topic: "" });
  }
};
const handleDel = (type, index) => {
  if (type === 1) {
    //up
    if (formData2.up.length == 1) return;
    formData2.up.splice(index, 1);
  } else {
    //down
    if (formData2.down.length == 1) return;
    formData2.down.splice(index, 1);
  }
};
const initFormData = async () => {
  let { result } = await apiGateWay.detail(props.detail.bizId);
  result.clientId = result.protocolDetail.clientId
  result.password = result.protocolDetail.password
  result.protocolType = result.protocolDetail.protocolType
  result.protocolTypeName = result.protocolDetail.protocolTypeName
  result.username = result.protocolDetail.username
  for (const key in formData1) {
    formData1[key] = result[key];
  }
  formData2.up = result.protocolDetail.upTopic;
  formData2.down = result.protocolDetail.downTopic;
};

const confirmLoading = ref(false);
const ok = async () => {
  let hasUpTopic = formData2.up.some((item) => item.topic);
  let hasDownTopic = formData2.down.some((item) => item.topic);
  if (hasUpTopic || hasDownTopic) {
    try {
      await formRef1.value.formRef.validateFields();
      const params = {
        ...formData1,
        protocolType: "01",
      };
      if (props.type === "edit") {
        params.id = props.detail.id;
      }
      params.upTopic = formData2.up;
      params.downTopic = formData2.down;
      confirmLoading.value = true;
      console.log(22, params);
      if (props.type === "edit") {
        await apiGateWay.edit(params);
        message.success(`修改成功`);
      } else {
        await apiGateWay.add(params);
        message.success(`保存成功`);
      }

      emit("update:visible", false);
      emit("success");
      confirmLoading.value = false;
    } catch (errorInfo) {
      confirmLoading.value = false;
      console.log("Failed:", errorInfo);
    }
  } else {
    message.success(`主题至少一个必填！`);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>

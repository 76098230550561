import { cloneDeep } from "lodash";
import { mainRouter as routes } from "./index";

export function checkHasTargetPath(targetPath, menus) {
  let findPath = false;
  let flag = false;
  for (let route of menus) {
    if (flag) break;
    if (route.path === targetPath || targetPath.includes(route.path)) {
      findPath = true;
      break;
    }
    if(targetPath.includes('/custom/') && route.path.includes(targetPath)){
      findPath = true;
      break;
    }
    if (Array.isArray(route.children)) {
      for (let childRoute of route.children) {
        if (childRoute.path === targetPath) {
          findPath = true;
          flag = true;
          break;
        }
      }
    }
  }
  return findPath;
}

//检查子菜单对应的权限菜单是否有权限
export function checkTargetPathMenuPath(targetPath, menus, permissionMenu, name) {
  let findPath = false;
  let menuRoute = {};
  let flag = false;
  let flag2 = false;
  for (let route of menus) {
    if (flag) break;
    //route.path === targetPath
    if (route.path === targetPath || name == route.name) {
      if(route.meta.activeMenu){
        let findOne = route.meta.activeMenu.find(item=>{
          return permissionMenu.find(menu=>{
            let flag = menu.path.includes(item)
            if(!flag&&menu.children&&menu.children.length>0){
              return menu.children.find(child=>child.path.includes(item))
            }
            return flag;
          })
        });
        if(findOne){
          findPath = true;
          break;
        }
      }else if(route.meta.noAuth){
        findPath = true;
        break;
      } 
    }
    if (Array.isArray(route.children)) {
      for (let childRoute of route.children) {
        if (flag2) break;
        if (childRoute.path === targetPath || name == childRoute.name) {
          if(childRoute.meta.activeMenu){
            let findOne = childRoute.meta.activeMenu.find(item=>{
              return permissionMenu.find(menu=>{
                let flag = menu.path.includes(item)
                if(!flag&&menu.children&&menu.children.length>0){
                  return menu.children.find(child=>child.path.includes(item))
                }
                return flag;
              })
            })
            if(findOne){
              findPath = true;
              flag = true;
              menuRoute = route;
              break;
            }
          }else if(childRoute.meta.noAuth){
            findPath = true;
            flag = true;
            menuRoute = route;
            break;
          }
        
        }
        if (Array.isArray(childRoute.children)) {
          for (let _childRoute of childRoute.children) {
            if (_childRoute.path === targetPath || name == _childRoute.name) {
              if(_childRoute.meta.parentPath){
                let findOne = _childRoute.meta.activeMenu.find(item=>{
                  return permissionMenu.find(menu=>{
                    let flag = menu.path.includes(item)
                    if(!flag&&menu.children&&menu.children.length>0){
                      return menu.children.find(child=>child.path.includes(item))
                    }
                    return flag;
                  })
                })
                if(findOne){
                  findPath = true;
                  flag = true;
                  flag2 = true;
                  menuRoute = childRoute;
                  break;
                }
              }else if(route.meta.noAuth){
                findPath = true;
                flag = true;
                flag2 = true;
                menuRoute = childRoute;
                break;
              }
            }
          }
        }
      }
    }
  }
  return {
    findPath,
    menuRoute,
  };
}

export function isPermissionMenu(target, permissionMenu, systems, isCacheTabs) {
  const { path,meta,name } = target;
  if(meta&&meta.noAuth)return true;
  let findSystemRoute = findCurrentSystemRoutePathPefix(path)
  if(!findSystemRoute )return false;
  if(!isCacheTabs && !findCode(findSystemRoute.code,systems) ){
    return false;
  }
  //if(findSystemRoute && !permissionMenu[0].path.includes(findSystemRoute.path) )return false;

  const _routeList = cloneDeep(findSystemRoute.children);
  let hasPathFlag = checkHasTargetPath(path, permissionMenu);
  if (hasPathFlag) {
    return true;
  }
  //菜单子页面权限验证
  let { findPath, menuRoute } = checkTargetPathMenuPath(path, _routeList, permissionMenu, name);
  if (findPath) {
    return true;
  } else {
    if(menuRoute&&menuRoute.redirect){
      let hasPathFlag = checkHasTargetPath(menuRoute.redirect, permissionMenu);
      if (hasPathFlag) {
        return true;
      }
    }
  }

  return false;

  //是菜单下面的子页面
}

//用于和后端返回的菜单对比，如果路由模块code，不在返回的里面，直接报错！
export function findCode(code, systems){
  
  let flag = systems&&systems.find(item=>item.moduleCode === code);
  return !!flag
}
  

//根据路径获取当前系统的所有路由
export function findCurrentSystemRoutePathPefix(path){
  return routes.find(routes=>path.includes(routes.path))
}
<template>
  <div class="project-list">
    <a-tabs v-model:activeKey="activeKey" @change="tabChange">
      <a-tab-pane key="1" tab="排放因子"></a-tab-pane>
      <a-tab-pane key="2" tab="消除因子" ></a-tab-pane>
    </a-tabs>
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotTitle="{ row }">
        <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.id}`,
                state: { tabName: row.id },
              }"
              >{{ row.title }}</router-link
            >
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import editModal from "../components/editModal";
import _ from "lodash";
import { apiDictListByCode } from "@/api/common";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initFilterOption();
  initTableData();
  initOptions();
});

const messageTypeMap = ref({});
const messageStatus = ref({});
const initOptions = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageTypeMap.value = _map;
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};

const filterSource = ref([
  {
    label: "因子项",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
]);

const initFilterOption = async () => {
  let { result } = await apiDictListByCode("CARBON_CONTENT_TYPE");
  let { result:result2 } = await apiDictListByCode("MESSAGE_STATUS");

  const _list = _.cloneDeep(filterSource.value);
  _list[0].options = result || [];
  _list[1].options = result2 || [];

  filterSource.value = _list;
};
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const baseColumn1= [
    {
      title: "ID",
      type: "text",
      key: "bizId",
      width: 120,
    },
    {
      title: "因子项",
      type: "text",
      key: "name",
    },
    {
      title: "单位",
      type: "text",
      key: "unit",
    },
    {
      title: "CO₂排放因子",
      type: "text",
      key: "co2Factor",
    },
    {
      title: "CH4排放因子",
      type: "text",
      key: "ch4Factor",
    },
    {
      title: "N₂O排放因子",
      type: "text",
      key: "n2oFactor",
    },
    {
      title: "SF6排放因子",
      type: "text",
      key: "sf6Factor",
    },
    {
      title: "HFCs排放气体",
      type: "text",
      key: "hfcsFactorDefine",
    },
    {
      title: "HFCs排放因子",
      type: "text",
      key: "hfcsFactor",
    },
    {
      title: "PFCs排放气体",
      type: "text",
      key: "pfcsFactorDefine",
    },
    {
      title: "PFCs排放因子",
      type: "text",
      key: "pfcsFactor",
    },
    {
      title: "关联设施活动",
      type: "text",
      key: "activityName",
    },
    {
      title: "关联排放源",
      type: "text",
      key: "sourceDesc",
    },
    {
      title: "排放因子等级",
      type: "text",
      key: "factorLevel",
    },
    {
      title: "排放因子质量分",
      type: "text",
      key: "factorQualityLevel",
    },
    {
      title: "参考来源",
      type: "text",
      key: "source",
    },
]
const baseColumn2=[
    {
      title: "ID",
      type: "text",
      key: "bizId",
      width: 120,
    },
    {
      title: "清除因子项",
      type: "text",
      key: "name",
    },
    {
      title: "补充描述",
      type: "text",
      key: "supplementDesc",
    },
    {
      title: "CO2固定量",
      type: "text",
      key: "co2FixedQuantity",
    },
    {
      title: "单位",
      type: "text",
      key: "unit",
    },
    {
      title: "关联设施活动",
      type: "text",
      key: "activityName",
    },
    {
      title: "参考来源",
      type: "text",
      key: "source",
    },
]
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
    x:"180%"
  },
  columns: baseColumn1
});
const activeKey = ref('1')
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    let api = activeKey.value == 1?'yinziPage':'xiaoyinziPage'
    const res = await apiCarbonInfo[api](params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};

const tabChange = (val)=>{
  if(val == 1){
    tableConfig.columns = baseColumn1
    tableConfig.scroll = {
      scrollToFirstRowOnChange: true,
      y: "calc(100vh - 320px)",
      x:"180%"
    }
  }else{
    tableConfig.columns = baseColumn2
    tableConfig.scroll = {
      scrollToFirstRowOnChange: true,
      y: "calc(100vh - 320px)",
      x:"100%"
    }
  }
  tableRef.value.paginationProps.current = 1;
  initTableData();
}
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);



const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  0px 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>

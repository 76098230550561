<template>
  <div class="common-wrapper" id="commonwrapper">
    <aside class="aside">
      <div class="aside-header">
        <div class="deliver">
          <a-tooltip
            v-if="
              data.protocolDetail && data.protocolDetail.protocolType != '99'
            "
          >
            <template #title>编辑</template>
            <FormOutlined @click="handleEdit" />
          </a-tooltip>
        </div>
        <div class="name">
          <a-typography-text
            v-if="data.name"
            :ellipsis="{ tooltip: data.name }"
            :content="data.name"
          ></a-typography-text>
        </div>
        <div class="id">{{ data.bizId }}</div>
        <div class="desc">
          <a-typography-paragraph
            v-if="data.description"
            :ellipsis="{ rows: 3, tooltip: data.description }"
            :content="data.description"
          />
        </div>
      </div>
      <!--MQTT网关-->
      <div
        class="aside-content"
        v-if="
          data.id &&
          data.protocolDetail &&
          data.protocolDetail.protocolType == '01'
        "
      >
        <div class="status">
          <div
            :class="[
              'switch-btn',
              { 'switch-btn-active': data.status == '01' },
            ]"
            style="cursor: pointer"
          >
            <check-outlined v-if="data.status == '01'" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">{{
              data.status === "01" ? "启用" : "停用"
            }}</span>
            <span
              class="tip"
              @click="changeStatus(data.status == '01' ? '02' : '01')"
              ><SwapOutlined /> 切换到{{
                data.status === "02" ? "启用" : "停用"
              }}</span
            >
          </div>
        </div>

        <div class="col1">
          <div class="attr-key">协议</div>
          <div class="attr-value">
            {{ data.protocolDetail.protocolTypeName }}
          </div>
        </div>
        <div class="col1">
          <div class="attr-key">Client ID</div>
          <div class="attr-value">{{ data.protocolDetail.clientId }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">用户名</div>
          <div class="attr-value">{{ data.protocolDetail.username }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">密码</div>
          <div class="attr-value passwords" v-if="hidePassword">
            <span>********</span
            ><EyeInvisibleOutlined @click="hidePassword = false" />
          </div>
          <div class="attr-value passwords" v-else>
            <span>{{ data.protocolDetail.password }}</span
            ><EyeOutlined @click="hidePassword = true" />
          </div>
        </div>
        <div class="col2">
          <div class="attr-key">接入项目</div>
          <div class="attr-value">{{ data.projectBizName }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">接入产品</div>
          <div class="attr-value" v-if="data.productNames">
            {{ data.productNames.join(",") }}
          </div>
        </div>
        <div class="col2">
          <div class="attr-key">上行主题</div>
          <div class="attr-value">
            <ul v-if="data.protocolDetail">
              <li v-for="item in data.protocolDetail.upTopic" :key="item.topic">
                {{ item.topic }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col2">
          <div class="attr-key">下行主题</div>
          <div class="attr-value">
            <ul v-if="data.protocolDetail">
              <li
                v-for="item in data.protocolDetail.downTopic"
                :key="item.topic"
              >
                {{ item.topic }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--ModbusTcpClient网关-->
      <div
        class="aside-content"
        v-if="
          data.id &&
          data.protocolDetail &&
          data.protocolDetail.protocolType == '02'
        "
      >
        <div class="status">
          <div
            :class="[
              'switch-btn',
              { 'switch-btn-active': data.status == '01' },
            ]"
            style="cursor: pointer"
          >
            <check-outlined v-if="data.status == '01'" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">{{
              data.status === "01" ? "启用" : "停用"
            }}</span>
            <span
              class="tip"
              @click="changeStatus(data.status == '01' ? '02' : '01')"
              ><SwapOutlined /> 切换到{{
                data.status === "02" ? "启用" : "停用"
              }}</span
            >
          </div>
        </div>

        <div class="col1">
          <div class="attr-key">协议</div>
          <div class="attr-value">
            {{ data.protocolDetail.protocolTypeName }}
          </div>
        </div>
        <div class="col1">
          <div class="attr-key">运行服务器</div>
          <div class="attr-value">
            {{ data.serverName }}
            <a-tooltip placement="right" color="#fff" @visibleChange="(info)=>handleGetInfo(info, data.serverId)">
              <template #title>
                <div class="tip-wrapper">
                  <section class="title">
                    {{ data.serverName }}信息：
                  </section>
                  <section class="tip-content">
                    <div>CPU使用：{{ currentServeInfo.cpuUsed }} %</div>
                    <div>可用内存：{{ currentServeInfo.memoryAvailable }} G</div>
                  </section>
                </div>
              </template>
              <info-circle-outlined :style="{color:'#0256ff',cursor:'pointer'}"/>
            </a-tooltip>
          </div>
        </div>
        <div class="col2">
          <div class="attr-key">接入项目</div>
          <div class="attr-value">{{ data.projectBizName }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">接入产品</div>
          <div class="attr-value" v-if="data.productNames">
            {{ data.productNames.join(",") }}
          </div>
        </div>
      </div>
      <!--定制网关-->
      <div
        class="aside-content"
        v-if="
          data.id &&
          data.protocolDetail &&
          data.protocolDetail.protocolType == '99'
        "
      >
        <div class="status">
          <div
            :class="[
              'switch-btn',
              { 'switch-btn-active': data.status == '01' },
            ]"
            style="cursor: pointer"
          >
            <check-outlined v-if="data.status == '01'" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">{{
              data.status === "01" ? "启用" : "停用"
            }}</span>
            <span
              class="tip"
              @click="changeStatus(data.status == '01' ? '02' : '01', true)"
              ><SwapOutlined /> 切换到{{
                data.status === "02" ? "启用" : "停用"
              }}</span
            >
          </div>
        </div>

        <div class="col1">
          <div class="attr-key">协议类型</div>
          <div class="attr-value">
            {{ data.protocolDetail.protocolTypeName }}
          </div>
        </div>
        <div class="col1">
          <div class="attr-key">协议名称</div>
          <div class="attr-value">{{ data.protocolDetail.name }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">接入项目</div>
          <div class="attr-value">{{ data.projectBizName }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">接入产品</div>
          <div class="attr-value" v-if="data.productNames">
            <span v-for="item in data.productNames" :key="item">
              {{ item }}</span
            >
          </div>
        </div>
      </div>
    </aside>
    <main class="content" v-if="showTab">
      <a-tabs v-model:activeKey="activeKey" v-if="data.protocolDetail">
        <a-tab-pane key="0" tab="运行监测">
          <run-monitor :data="data" />
        </a-tab-pane>
        <a-tab-pane
          key="1"
          tab="JS脚本处理"
          v-if="data.protocolDetail.protocolType == '01'"
        >
          <script-deal :data="data" />
        </a-tab-pane>
        <a-tab-pane
          key="2"
          tab="报文监听"
          v-if="data.protocolDetail.protocolType == '01'"
        >
          <keep-alive>
            <message-monitor :key="data.id" />
          </keep-alive>
        </a-tab-pane>
        <a-tab-pane
          key="3"
          tab="设备列表"
          v-if="data.protocolDetail.protocolType == '99'"
        >
          <div class="not-finish">
            <div>
              <img src="../../../../assets/not-finish.png" alt="" srcset="" />
              <p>当前功能，正在建设中...</p>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane
          key="4"
          tab="配置文件"
          v-if="data.protocolDetail.protocolType == '02'"
        >
          <config-file :data="data" />
        </a-tab-pane>
      </a-tabs>
    </main>
  </div>
  <editModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    :type="modalType"
    @success="editSuccess"
  ></editModal>
  <editMdBusModal
    v-if="modalMdBusVisible"
    v-model:visible="modalMdBusVisible"
    :detail="currentRow"
    :type="modalType"
    @success="editSuccess"
  ></editMdBusModal>
</template>

<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { FormOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import scriptDeal from "./components/scriptDeal";
import runMonitor from "./components/runMonitor";
import configFile from "./components/configFile";
import messageMonitor from "./components/messageMonitor";
import editMdBusModal from "../components/editModalMdBus";
//import { useUserStore } from "@/store/modules/user";
import { apiGateWay } from "@/api/gateway.js";
import editModal from "../components/editModal";
// const userStore = useUserStore();

const route = useRoute();
const data = ref({});
const activeKey = ref("0");
const showTab = ref(true);
const hidePassword = ref(true);

onMounted(() => {
  initBaseInfo();
});

watch(
  () => route.fullPath,
  () => {
    if (route.fullPath.includes("/IoTManage/gateway/detail")) {
      nextTick(() => {
        initBaseInfo();
        activeKey.value = "0";
      });
    }
  }
);

const initBaseInfo = () => {
  apiGateWay.detail(route.params.bizId).then(({ result }) => {
    data.value = result;
  });
};

const modalMdBusVisible = ref(false);
const modalVisible = ref(false);
const modalType = ref("edit");
const currentRow = ref({});
const handleEdit = () => {
  if (data.value.status === "01") {
    message.warning("网关启动状态，禁止编辑！请先停止网关，再操作！");
    return;
  }
  currentRow.value = {
    ...data.value,
  };
  if(data.value.protocolDetail.protocolType == '01'){
    modalVisible.value = true;
  }else if(data.value.protocolDetail.protocolType == '02'){
    modalMdBusVisible.value = true;
  }
  
};
const editSuccess = () => {
  initBaseInfo();
};

const currentServeInfo = ref({})
const handleGetInfo = async (visible,id)=>{
  if(visible){
    let {result} = await apiGateWay.getServeDetail(id)
    currentServeInfo.value = result || {}
  }
}

const changeStatus = (status, flag) => {
  if (status != data.value.status) {
    if (status == "02") {
      Modal.confirm({
        title: "提醒",
        content: "请确认是否停用网关？",
        onOk() {
          return new Promise((resolve) => {
            apiGateWay
              .stopGateway(route.params.bizId)
              .then(() => {
                initBaseInfo();
                message.success("切换成功");
              })
              .finally(() => {
                resolve();
              });
          });
        },
      });
    }
    if (status == "01") {
      if (flag === true) {
        //定制网关
        apiGateWay
              .startGateway(route.params.bizId)
              .then(() => {
                initBaseInfo();
                message.success("切换成功");
              })
              .finally(() => {});
      } else {
        //mqtt需要先校验 js
        apiGateWay.checkGatewayJSInit(route.params.bizId).then((res) => {
          if (!res.result) {
            Modal.confirm({
              title: "提醒",
              content:
                "JS脚本还处于初始化状态。现在启用网关，数据处理过程可能异常。是否继续启用？",
              onOk() {
                return new Promise((resolve) => {
                  apiGateWay
                    .startGateway(route.params.bizId)
                    .then(() => {
                      initBaseInfo();
                      message.success("切换成功");
                    })
                    .finally(() => {
                      resolve();
                    });
                });
              },
            });
          } else {
            apiGateWay
              .startGateway(route.params.bizId)
              .then(() => {
                initBaseInfo();
                message.success("切换成功");
              })
              .finally(() => {});
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.common-wrapper {
  position: relative;
  background: none;
  padding: 0;
  height: 100%;
}

.aside {
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  overflow: auto;

  &-header {
    padding: 18px 20px 20px;

    .deliver {
      color: #0256ff;
      font-size: 12px;
      text-align: right;
      height: 14px;
    }

    .name {
      font-weight: bold;
      color: #38404c;
    }

    .id {
      color: #999;
      line-height: 38px;
    }

    .desc {
      font-size: 14px;
      line-height: 16px;
    }

    :deep .ant-typography {
      margin: 0;
      color: #666;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e6e8;

    .status {
      display: flex;
      align-items: center;
      height: 40px;
      color: #fff;
      width: 100%;

      .switch-btn {
        position: relative;
        display: inline-block;
        height: 100%;
        cursor: pointer;
        width: 100%;
        line-height: 40px;
        background-color: #d9d9d9;
        text-align: center;
        color: #fff;
        transition: width 0.5s;
        .tip {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          background-color: #799aff;
        }
      }
      .switch-btn:hover {
        .tip {
          display: block;
        }
      }

      .switch-btn-active {
        background-color: #0256ff;
        flex: 1;
      }
    }

    .col1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      width: 50%;
    }

    .attr-key {
      color: #999;
      font-size: 12px;
      line-height: 20px;
    }

    .attr-value {
      color: #38404c;
      font-size: 14px;
      line-height: 24px;
    }

    .col2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      padding: 5px 10px;
      width: 100%;
      .attr-value {
        display: flex;
        flex-wrap: wrap;
        span {
          text-align: left;
          display: block;
          width: 100%;
        }
      }
      .attr-key {
        text-align: left;
      }
    }

    .col1:nth-child(2n) {
      border-right: 1px solid #e5e6e8;
    }
  }
}

.content {
  position: relative;
  margin-left: 248px;
  padding: 0 20px;
  height: 100%;
  background-color: #fff;
}
.passwords {
  span {
    margin-right: 4px;
  }
}
.not-finish {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    margin-top: 100px;
    p {
      margin-top: 10px;
    }
  }
}
.tip-wrapper{
  color: #333;
  .title{
    margin-bottom: 10px;
    border-bottom: 1px solid #fff;
    font-size: 14px;
    border-bottom: 1px solid #cdcdcd;
  }
  .tip-content{
    font-size: 14px;
    color: #888;
  }
}
</style>

<template>
  <BasicModal
    :title="
      type === 'edit' ? '修改ModbusTcpClient网关' : '新增ModbusTcpClient网关'
    "
    width="950px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
      <div>
        <a-form
          ref="formRef2"
          :model="formData2"
          name="basic"
          layout="horizontal"
          autocomplete="off"
          class="form2"
        >
        </a-form>
      </div>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiGateWay } from "@/api/gateway.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: "add",
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);
const formRef2 = ref(null);

onMounted(() => {
  initFormItems();

  if (props.type === "edit") {
    initFormData();
  }
});

const formItemsMap1 = reactive({
  name: {
    label: "网关名称",
    prop: "name",
    type: "input",
    required: true,
    span: 11,
    requiredMessage: "请输入",
  },
  project: {
    label: "接入项目",
    prop: "projectBizId",
    type: "select",
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    disabled: props.type === "edit" ? true : false,
    required: true,
    options: [],
    span: 11,
    requiredMessage: "请选择",
  },
  product: {
    label: "接入产品",
    prop: "productBizIds",
    type: "select",
    disabled: props.type === "edit" ? true : false,
    fieldNames: {
      label: "name",
      value: "bizId",
    },
    maxTagCount: 1,
    mode: "multiple",
    options: [],
    required: true,
    span: 11,
    requiredMessage: "请选择",
  },
  area: {
    label: "网关描述",
    prop: "description",
    type: "input",
    span: 11,
  },
  serverName: {
    label: "运行服务器",
    prop: "serverId",
    type: "select",
    fieldNames: {
      label: "label",
      value: "serverId",
    },
    required: true,
    options: [],
    span: 11,
    requiredMessage: "请选择",
  },
});
const initFormItems = async () => {
  apiGateWay.getProducts().then((res) => {
    formItemsMap1.product.options = res.result;
  });

  apiGateWay.getProject().then((res) => {
    formItemsMap1.project.options = res.result;
  });

  apiGateWay.getService().then((res) => {
    res.result &&
      res.result.forEach((item) => {
        item.label = `${item.serverName}( cpu: ${item.cpuUsed}% ; free men: ${item.memoryAvailable}G )`;
      });
    formItemsMap1.serverName.options = res.result;
  });
};
const formData1 = reactive({
  name: "",
  projectBizId: "",
  productBizIds: [],
  description: "",
  serverId: "",
});

const initFormData = async () => {
  let { result } = await apiGateWay.detail(props.detail.bizId);
  result.clientId = result.protocolDetail.clientId;
  result.protocolType = result.protocolDetail.protocolType;
  result.protocolTypeName = result.protocolDetail.protocolTypeName;
  for (const key in formData1) {
    formData1[key] = result[key];
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      ...formData1,
      protocolType: "02",
    };
    if (props.type === "edit") {
      params.id = props.detail.id;
    }
    confirmLoading.value = true;
    if (props.type === "edit") {
      await apiGateWay.edit(params);
      message.success(`修改成功`);
    } else {
      await apiGateWay.add(params);
      message.success(`保存成功`);
    }

    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>

<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotTitle="{ row }">
        <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.id}`,
                state: { tabName: row.id },
              }"
              >{{ row.title }}</router-link
            >
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import editModal from "../components/editModal";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});

const filterSource = ref([
  {
    label: "排放源",
    type: "input",
    bindKey: "source",
    placeholder: "请输入",
  },
  {
    label: "设施/活动",
    type: "input",
    bindKey: "activityName",
    placeholder: "请输入",
  },
]);

let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "bizId",
      width: 120,
    },
    {
      title: "设施/活动",
      type: "text",
      key: "activityName",
    },
    {
      title: "排放源",
      type: "text",
      key: "source",
    },
    {
      title: "排放类别",
      type: "text",
      key: "subitem",
    },
    {
      title: "计量单位",
      type: "text",
      key: "unit",
    },
  ],
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiCarbonInfo.activityPage(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);





const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  20px 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>

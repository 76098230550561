<template>
  <BasicModal
    :title="info && info.id ? '编辑目标' : '创建目标'"
    width="600px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
        @change="formChange"
      >
      <template #reportUrl="{form}">
        <div>
          <UploadButton label="上传报告" fileType="file" :customFunc="customUpload" @uploadSuccess="uploadSuccess(form)">
              </UploadButton>
        </div>
      </template>
    </BasicForm>
      
    </div>
    <div class="actions">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="ok"
        >保存</a-button
      >
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiCarbonInfo } from "@/api/carbon.js";
import { apiFileUpload } from "@/api/common.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: null,
  },
  id: String,
  rangeId: String,
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);


onMounted(async () => {
  if (props.info) {
    initDetail();
    getValue()
  }
  initFormItems();
  
});

const initDetail = () => {
 for(let key in formData1){
  formData1[key] = props.info[key]
 }
};

const formItemsMap1 = reactive({
  classificationId: {
    label: "选择类别",
    prop: "classificationId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  subitemId: {
    label: "选择子类",
    prop: "subitemId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  activityId: {
    label: "活动设施",
    prop: "activityId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  factorId: {
    label: "碳排因子",
    prop: "factorId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    hide: props.rangeId == 1,
    requiredMessage: "请选择",
  },
  factorId2: {
    label: "消除因子",
    prop: "factorId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    hide: props.rangeId != 1,
    requiredMessage: "请选择",
  },
  activityLevelTargetValue: {
    label: "水平目标",
    prop: "activityLevelTargetValue",
    type: "input",
    required: true,
    span: 18,
    suffix:'',
    requiredMessage: "请输入",
  },
  targetVal: {
    label: "碳目标值",
    prop: "targetVal",
    type: "input",
    required: false,
    span: 18,
    suffix:'',
    disabled: true,
  },
});
const formData1 = reactive({
  classificationId:'',
  subitemId: "",
  activityId: '',
  factorId: "",
  activityLevelTargetValue: "",
  targetVal:''
});

const formChange = ({ prop })=>{
  if(prop === 'classificationId'){
      formData1.subitemId = '';
      formItemsMap1.subitemId.options = [];
      formData1.activityId = '';
      formItemsMap1.activityId.options = [];
      formData1.factorId = '';
      formItemsMap1.factorId.options = [];
      formItemsMap1.factorId2.options = [];
      formData1.activityLevelTargetValue = '';
      formData1.targetVal = '';
      apiCarbonInfo.subTypeList({
        classificationId: formData1.classificationId,
        rangeId: props.rangeId
      }).then(res=>{
        formItemsMap1.subitemId.options = res.result.map(item=>{
          return {
            label: item.subClassification,
            value: item.id
          }
        })
      })
  }
  if(prop === 'subitemId'){
      formData1.activityId = '';
      formItemsMap1.activityId.options = [];
      formData1.factorId = '';
      formItemsMap1.factorId.options = [];
      formItemsMap1.factorId2.options = [];
      formData1.activityLevelTargetValue = '';
      formData1.targetVal = '';

      apiCarbonInfo.activityList({
        subitemId: formData1.subitemId,
      }).then(res=>{
          formItemsMap1.activityId.options = res.result.map(item=>{
            return {
              label: item.activityName,
              value: item.id,
              unit: item.unit
            }
          })
      })
  }
  if(prop === 'activityId'){
      formData1.factorId = '';
      formItemsMap1.factorId.options = [];
      formItemsMap1.factorId2.options = [];
      formData1.activityLevelTargetValue = '';
      formData1.targetVal = '';

      if(props.rangeId == 1){
        apiCarbonInfo.xiaoyinziList({
          activityId: formData1.activityId
        }).then(res=>{
          formItemsMap1.factorId2.options = res.result.map(item=>{
            return {
              label: item.name,
              value: item.id,
              unit: item.unit
            }
          })
        })
      }else{
        apiCarbonInfo.yinziList({
          activityId: formData1.activityId
        }).then(res=>{
          formItemsMap1.factorId.options = res.result.map(item=>{
            return {
              label: item.name,
              value: item.id,
              unit: item.unit
            }
          })
        })
      }

      let list = formItemsMap1.activityId.options
      
      let findOne = list.find(item=>item.value == formData1.activityId)
      console.log(123,findOne, list, formData1.activityId)
      if(findOne){
        formItemsMap1.activityLevelTargetValue.suffix=findOne.unit
      }
  }
  if(prop === 'factorId'){
    formData1.activityLevelTargetValue = '';
    formData1.targetVal = '';
    let list = props.rangeId == 1?formItemsMap1.factorId2.options:formItemsMap1.factorId.options
    let findOne = list.find(item=>item.value == formData1.factorId)
    if(findOne){
      formItemsMap1.targetVal.suffix=findOne.unit&&findOne.unit.split('/')[0]
    }
  }
  getValue()
}

const getValue = ()=>{
  if(
      formData1.classificationId &&
      formData1.subitemId &&
      formData1.activityId &&
      formData1.factorId &&
      formData1.activityLevelTargetValue
    ){
      apiCarbonInfo.targetValueDeal(
        {
          classificationId:formData1.classificationId,
          targetId:props.id,
          rangeId:props.rangeId*1,
          subitemId: formData1.subitemId,
          activityId: formData1.activityId,
          factorId: formData1.factorId,
          activityLevelTargetValue: formData1.activityLevelTargetValue,
        }
      ).then(res=>{
        formData1.targetVal = res.result
      })
    }
}

const initFormItems = async () => {

  apiCarbonInfo.typeList({
    rangeId: props.rangeId
  }).then(res=>{
    formItemsMap1.classificationId.options = res.result.map(item=>{
      return {
        label: item.classification,
        value: item.id
      }
    })
  })

  if(props.info){
    apiCarbonInfo.subTypeList({
        classificationId: formData1.classificationId,
        rangeId: props.rangeId
      }).then(res=>{
        formItemsMap1.subitemId.options = res.result.map(item=>{
          return {
            label: item.subClassification,
            value: item.id
          }
        })
    })
    apiCarbonInfo.activityList({
        subitemId: formData1.subitemId,
      }).then(res=>{
          formItemsMap1.activityId.options = res.result.map(item=>{
            return {
              label: item.activityName,
              value: item.id,
              unit: item.unit
            }
          })
          let list = formItemsMap1.activityId.options
          let findOne = list.find(item=>item.value == formData1.activityId)
          if(findOne){
            formItemsMap1.activityLevelTargetValue.suffix= findOne.unit
          }
    })
    if(props.rangeId == 1){
        apiCarbonInfo.xiaoyinziList({
          activityId: formData1.activityId
        }).then(res=>{
          formItemsMap1.factorId2.options = res.result.map(item=>{
            return {
              label: item.name,
              value: item.id,
              unit: item.unit
            }
          });
          let list = formItemsMap1.factorId2.options
          let findOne = list.find(item=>item.value == formData1.factorId)
          if(findOne){
            formItemsMap1.targetVal.suffix=findOne.unit&&findOne.unit.split('/')[0]
          }
        })
      }else{
        apiCarbonInfo.yinziList({
          activityId: formData1.activityId
        }).then(res=>{
          formItemsMap1.factorId.options = res.result.map(item=>{
            return {
              label: item.name,
              value: item.id,
              unit: item.unit
            }
          });
          let list = formItemsMap1.factorId.options
          let findOne = list.find(item=>item.value == formData1.factorId)
          if(findOne){
            formItemsMap1.targetVal.suffix=findOne.unit&&findOne.unit.split('/')[0]
          }
        })
    }
    

  }

};
const customUpload = async (data) => {
  
  let res =  await apiFileUpload.file(data);
  console.log(333334444, data);
  formData1.reportUrl = res.result
};
const uploadSuccess = () => {
   
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      classificationId:formData1.classificationId,
      targetId:props.id,
      rangeId:props.rangeId*1,
      subitemId: formData1.subitemId,
      activityId: formData1.activityId,
      factorId: formData1.factorId,
      activityLevelTargetValue: formData1.activityLevelTargetValue,
    };
    if (props.info) {
      params.id = props.info.id;
    }
    await apiCarbonInfo.targetSave(params);
    message.success(`${params.id ? "编辑成功" : "保存成功"}！`);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;
  button {
    margin-left: 10px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>

<template>
  <a-modal :visible="openFlag" title="测试格式结果" @cancel="handleCancel">
    <div>
      <section class="topic">
        <p>返回结果：</p>
        <div>
          <pre v-html="JSON.stringify(data.desc, null, 3)"></pre>
        </div>
      </section>
      <p>
        模拟运行：
        <a-tag color="#87d068" v-if="data.status == '01'">成功</a-tag>
        <a-tag color="#f50" v-else>失败</a-tag>
      </p>
    </div>
    <template #footer>
      <a-button key="back" @click="handleEditJs">修改文件</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleRestart"
        >重新测试</a-button
      >
    </template>
  </a-modal>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  openFlag: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object,
    default(){
      return {}
    }
  }
});

const emit = defineEmits(["close","editJs",'restart']);
const handleCancel = () => {
  emit("close");
};

const handleEditJs = ()=>{
  emit("editJs");
}

const handleRestart = ()=>{
  emit("restart");
}


</script>
<style lang="less">
p {
  margin-bottom: 4px;
}
.topic {
  margin-bottom: 20px;
  div{
    padding: 16px;
    height: 217px;
    overflow:auto;
    border: 1px solid #ccc;
  }
}
</style>

<template>
    <div class="article_edit">
      <div>
            <div class="top">
            <section class="info">
                <h1>{{ content.title }}</h1>
                <div>
                    <span>{{ content.contentTypeName }}</span>
                    <span>{{ content.articleTypeName }}</span>
                    <span>{{ dayjs(content.createTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </div>
            </section>
        </div>
        <div class="content">
            <div ref="contentRef" v-html="content.content"></div>
        </div>
      </div>  
  </div>
</template>
<script setup>
import { onActivated, ref } from 'vue';
import { apiCarbonInfo } from "@/api/carbon.js";
import dayjs from "dayjs";
import { useRoute, onBeforeRouteUpdate } from 'vue-router';

const _id = ref('');
const content = ref('');
const route = useRoute();
const contentRef = ref();

const initData = ()=>{
    apiCarbonInfo.viewDetail({
        id: _id.value
    }).then(res=>{
        content.value = res.result;
        console.log(213, contentRef.value)
    })
}
onBeforeRouteUpdate((to, from) => {
  if (to.path != from.path) {
    _id.value = to.params?.id ?? "";
    initData();
  }
});

onActivated(() => {
    _id.value = route.params?.id ?? "";
    initData();
});

</script>
<style lang='less' scoped>

.article_edit{
  min-height: 100%;
  padding: 20px;
  background: #fff;
  &>div{
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #e5e6e8;
    border-radius: 4px;
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
  }
  
  
  .top{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      .info{
        text-align: center;
      }
      h1{
          font-size: 16px;
          font-weight: bold;
      }
      span{
          display: inline-block;
          color: #888;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          border-right: 2px solid #aaa;
          padding-right: 8px;
          padding-left: 8px;
          &:last-child{
            border-right: none;
          }
          &:first-child{
            padding-left: 0;
          }
      }
      button{
          margin-left: 16px;
      }
  }
}
:deep .content{
    img{
        max-width: 100% !important;
        height: auto !important;
    }
  }
</style>
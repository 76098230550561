<template>
    <div class="myEditor " :class="{'disabled': readonly}">
      <vue3-tinymce 
        v-model="state.content" 
        :setting="state.setting" 
        :key="code"
        script-src="/tinymce/tinymce.min.js"
      />
    </div>
</template>
<script  setup>
import Vue3Tinymce from '@jsdawn/vue3-tinymce';
import {reactive,defineProps,defineEmits,watch} from 'vue'
import http from "@/utils/http";
import { useUserStore } from "@/store/modules/user";

const props = defineProps({
  content:{
    type: String,
    default:''
  },
  readonly:{
    type: Boolean,
    default: false
  },
  code:{ //用于多页面切换时候的内容更新
    type: Number,
  }
})
const userStore = useUserStore();
const emits = defineEmits(['update:content'])

const state = reactive({
  content: '',
  // editor 配置项
  setting: {
    height: 680, // editor 高度
    language: 'zh_CN',
    language_url: '/tinymce/langs/zh_CN.js',
    // 开启组件拓展的 上传图片功能，工具栏 图片按钮 弹框中出现 `upload` 选项
      toolbar:
      'undo redo | fullscreen | blocks alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontsize forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
    toolbar_mode: 'sliding',
    quickbars_selection_toolbar:
      'removeformat | bold italic underline strikethrough | fontsize forecolor backcolor',
    plugins: 'link image media table lists fullscreen quickbars',
    font_size_formats: '12px 14px 16px 18px',
    link_default_target: '_blank',
    link_title: false,
    nonbreaking_force_tab: true,
    custom_images_upload: true,
    // 复用 图片上传 api 地址
    images_upload_url: `${http.axios.defaults.baseURL}/cemp-file/file/upload/anonymous?fileType=IMAGE`,
    // 上传成功回调函数，return 图片地址。默认 response.location
    custom_images_upload_callback: response =>response.result,
    // 上传 api 请求头
    custom_images_upload_header: { 
      "Authorization": userStore.token
    },
    // 上传 api 额外的参数。图片默认 file
    custom_images_upload_param: {}
  }
});

watch(()=>props.content,(news)=>{
  state.content = news;
});
watch(()=>state.content,(news)=>{
  emits('update:content',news)
});
</script>
<style lang='less' scoped>
.myEditor{
  cursor: not-allowed;
  &.disabled{
    position:relative;
    left:0;
    top:0;
    opacity:.6;
    width:100%;
    height:100%;
    background:#000;
    z-index:998;
    /*pointer-events: none; 是可以点击下面的事件，但是因为div是包着tinymac的，所以无效 */
    pointer-events: none;
    border-radius: 12px;
  }
}
</style>
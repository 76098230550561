<template>
    <div class="module-list">
      <div class="module-list-header">
        <appstore-outlined />
        <span>碳排主体</span>
      </div>
      <div class="module-list-body">
        <div class="module-list-scorll" v-if="list.length">
          <div
            class="module-list-item"
            :class="[
              'module-list-item',
              item.code === selectValue
                ? 'module-list-item-active'
                : '',
            ]"
            v-for="item in list"
            :key="item.code"
            @click="selectItem(item)"
          >
            <a-typography-text
              :style="{ width: '100%' }"
              :ellipsis="{ tooltip: item.name }"
              :content="item.name"
            />
            <div
              class="module-list-item-checked"
              v-if="isChecked(item.code)"
            >
              <component
                :is="$antIcons.CheckOutlined"
                :style="{ color: '#0256FF' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineEmits, defineProps } from "vue";
  
  const props = defineProps({
    list: {
      type: Array,
      default: () => [],
    },
    selectValue: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    checkedValue: {
      type: Array,
      default: () => [],
    },
  });
  const emit = defineEmits(["update:selectValue", "change"]);
  
  const selectItem = (item) => {
    emit("update:selectValue", item.code);
    emit("change", item.code);
  };
  
  const isChecked = (moduleCode) => {
    return props.checkedValue.includes(moduleCode);
  };
  </script>
  
  <style lang="less" scoped>
  .module-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
    &-header {
      display: flex;
      align-items: center;
      gap: 6px;
      line-height: 1;
      padding: 24px 20px 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
    &-body {
      flex: 1;
      overflow-y: auto;
    }
    &-scorll {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 0 8px;
    }
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 0 0 16px;
      line-height: 1;
      border-radius: 2px;
      cursor: pointer;
      ::v-deep .ant-typography {
        color: #4e5969;
      }
      &:hover {
        background: #f2f3f5;
        ::v-deep .ant-typography {
          color: #0256ff;
        }
      }
      &-active {
        background: #f2f3f5;
        ::v-deep .ant-typography {
          color: #0256ff;
        }
      }
      &-checked {
        position: absolute;
        right: 8px;
      }
    }
  }
  </style>
  
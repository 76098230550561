<template>
    <a-modal v-model:visible="visibleFlag" width="480px" title="修改密码" @ok="handleOk" @cancel="handleClose">
        <div>
            <div>
                <a-form :rules="rules" ref="formRef" :model="formData" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
                    autocomplete="off">
                    <a-form-item label="原密码" name="oldpassword" :rules="[{ required: true, message: '请输入原密码' }]">
                        <a-input v-model:value="formData.oldpassword" style="width: 280px;" />
                    </a-form-item>
                    <a-form-item  has-feedback label="新密码" name="newpassword">
                        <a-input v-model:value="formData.newpassword" style="width: 280px;" />
                    </a-form-item>
                    <a-form-item label="确认新密码" name="rnewpassword" :rules="[{ required: true, message: '请输入确认新密码' }]">
                        <a-input v-model:value="formData.rnewpassword" style="width: 280px;" />
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </a-modal>
</template>
<script setup>
import { defineProps, defineEmits, computed, reactive, ref } from 'vue'
//import { apiDeviceCmdWrite } from '@/api/centerMonitor/projectMonitor.js'
import { message } from "ant-design-vue";
import md5 from "crypto-js/md5";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close', 'ok'])

const validatePass = async (_rule, value) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,30}$/
    if (value === '') {
        return Promise.reject('请输入密码');
    } else if (!reg.test(value)) {
        return Promise.reject("密码至少包含大,小写字母，数字，且不少于8位!");
    } else {
        return Promise.resolve();
    }
};

const rules = {
    newpassword:[{validator: validatePass, trigger:'change'}]
}

const formData = reactive({
    oldpassword: '',
    newpassword: '',
    rnewpassword: '',
})

const visibleFlag = computed(() => {
    return props.visible
})

const handleClose = () => {
    emits('close')
}

const formRef = ref()
const handleOk = () => {
    formRef.value
        .validate()
        .then(async () => {

            if (formData.newpassword !== formData.rnewpassword) {
                message.warning("两次密码输入不一致！");
                return;
            }
            emits("ok", { newPassword: md5(formData.newpassword).toString(), oldPassword: md5(formData.oldpassword).toString(), confirmPassword: md5(formData.rnewpassword).toString() });
        })
        .catch((error) => {
            console.log("error", error);
        });
}
</script>
<style lang='less' scoped>
.models {
    display: flex;
    margin-bottom: 25px;

    span {
        width: 88px;
        height: 32px;
        border-radius: 2px;
        align-items: center;
        padding: 5px 16px;

        &.active {
            background: #0256FF;
            color: #fff;
        }
    }
}
</style>
<template>
    <div class="category-search" id="categorySearch">
        <div class="search_icon">
            <search-outlined />
        </div>
        <div class="search-container" v-clickOutside="{ fn: handleHideFilterList }">
            <div class="container_wrap" :style="component_container_scroll_style" ref="container_wrap"
                @scroll="handleScroll" @mouseenter="showScroll = true" @mouseleave="showScroll = false">
                <div>
                    <ul>
                        <li v-clickOutside="{ fn: handleClickOutSide, currentIndex: index }" :key="index"
                            v-for="(item, index) in filterTagList" :ref="el => TagRefs[index] = el">
                            <div>
                                <section class="block-item">
                                    <span>{{ item.label }}：</span>
                                    <strong v-if="item.type !== 'select' && item.type !== 'rangePicker'">{{ item.bindValue
                                    }}</strong>
                                    <div v-if="item.type === 'select'" class="lableList">
                                        <span v-for="(_i, index) in item.labelValueList" :key="_i.value">
                                            {{ item.fieldNames ? _i[item.fieldNames.label] : _i.label }}
                                            <a-divider type="vertical" v-if="index != item.labelValueList.length - 1" />
                                        </span>
                                    </div>
                                    <div v-if="item.type === 'rangePicker'">

                                        <span v-for="(_i, index) in item.timeList" :key="index">
                                            {{ _i }}
                                            <em v-if="index != 1"> ~ </em>
                                        </span>
                                    </div>
                                    <!-- <a-input /> -->
                                </section>
                                <CloseCircleOutlined class="close-icon" @click.stop="delFilterTag(index, item)" />
                            </div>
                        </li>
                    </ul>
                    <div class="search-input" ref="searchInput">
                        <a-input @focus="handleInputFocus" @blur="handleInputBlur" ref="seachInputRef"
                            :placeholder="seachInputPlaceHolder" @pressEnter="handlePressEnter" @input="inputHandle">
                            <template #enterButton>
                                <a-button>Custom</a-button>
                            </template>
                        </a-input>
                    </div>
                </div>
            </div>
            <div :style="component_container_wrap_style" class="component_container_wrap" ref="component_container_wrap">
                <div class="component_container filterList" v-if="filterListVisible">
                    <ol>
                        <li v-for="(item, index) in filterList" :key="item.bindKey"
                            @click="handleShowFilterTag(index, item)" :class="{ disabled: item.disabled }">
                            {{ item.label }}
                            <right-outlined class="arrow" v-if="item.type !== 'input'" />
                        </li>
                        <li v-show="filterList.length < 1" class="no_more">
                            <p>暂无更多选项</p>
                        </li>
                    </ol>
                </div>
                <div class="component_container filterComponent" v-if="showFilterComponentFlag">
                    <template v-if="currentFilterItem.type === 'select'">
                        <div>
                            <a-select ref="selectRef" v-model:value="filterForms[currentFilterItem.bindKey]"
                                :mode="currentFilterItem.multiple ? 'multiple' : null"
                                :placeholder="currentFilterItem.placeholder ? currentFilterItem.placeholder : '请选择'"
                                :showSearch="currentFilterItem.search ? currentFilterItem.search : false"
                                :fieldNames="currentFilterItem.fieldNames ? currentFilterItem.fieldNames : null"
                                :filterOption="currentFilterItem.search ? filterSelectOption : null"
                                :getPopupContainer="triggerNode => triggerNode.parentNode"
                                :options="currentFilterItem.options"
                                :style="{ width: currentFilterItem.width ? (currentFilterItem.width + 'px') : '220px' }"
                                defaultOpen @blur="selectBlur" @change="(...arg) => selectChange(arg, currentFilterItem)" />

                        </div>
                    </template>
                    <template v-if="currentFilterItem.type === 'rangePicker'">
                        <div>
                            <a-range-picker :show-time="currentFilterItem.showTime"
                                :getPopupContainer="triggerNode => triggerNode.parentNode"
                                v-model:value="filterForms[currentFilterItem.bindKey]" class="hide_item"
                                ref="rangePickerRef" :open="pickOpenFlag"
                                @change="(...arg) => handlePickChange(arg, currentFilterItem)"
                                :style="{ width: currentFilterItem.width ? (currentFilterItem.width + 'px') : '' }">
                                <template #renderExtraFooter>
                                    <a-button type="primary" @click="handlePickOk">确定</a-button>
                                </template>
                            </a-range-picker>
                        </div>
                    </template>
                    <template v-if="currentFilterItem.type === 'datePicker'">
                        <div>
                            <a-date-picker 
                                :getPopupContainer="triggerNode => triggerNode.parentNode"
                                :picker="currentFilterItem.picker"
                                v-model:value="filterForms[currentFilterItem.bindKey]" class="hide_item"
                                :format="currentFilterItem.format"
                                ref="rangeDatePickerRef" :open="datePickOpenFlag"
                                @change="(...arg) => handleDatePickChange(arg, currentFilterItem)"
                                :style="{ width: currentFilterItem.width ? (currentFilterItem.width + 'px') : '' }">
                                <template #renderExtraFooter>
                                    <a-button type="primary" @click="handlePickOk">确定</a-button>
                                </template>
                            </a-date-picker>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, defineProps, reactive, watch, nextTick, computed, defineEmits } from 'vue';
import _ from "lodash";
import dayjs from 'dayjs';

const rangePickerRef = ref(null)
const rangeDatePickerRef = ref(null)
const seachInputRef = ref(null)
const selectRef = ref(null)
const seachInputPlaceHolder = ref('')
const TagRefs = ref([])

const component_container_wrap = ref(null)

const container_wrap = ref(null)
const searchInput = ref(null)

const props = defineProps({
    source: {
        type: Array,
        required: true
    }
})

const filterForms = reactive({})

const currentFilterItem = ref(null)

const showFilterComponentFlag = ref(false)
const currentTagOffsetLeft = ref(0)


const component_container_wrap_style = computed(() => {
    return {
        left: currentTagOffsetLeft.value + 'px'
    }
})

const showScroll = ref(false)
const component_container_scroll_style = computed(() => {
    return {
        'overflow-x': showScroll.value ? 'auto' : 'hidden'
    }
})

const emits = defineEmits(['change'])
const handleDealResult = () => {
    let cloneData =  _.cloneDeep((filterForms))
    let keys = Object.keys(cloneData)
    let findTimeKey = keys.find(key=>key.includes(','))
    for(let key in cloneData){
        if(Array.isArray(cloneData[key])){
            cloneData[key] = cloneData[key].join(',')
        }
    }
    let filterData = {}
    keys.forEach(key=>{
        if(key !== findTimeKey && (cloneData[key] || cloneData[key] === 0)){
            filterData[key] = cloneData[key]
        }
    })
    if(findTimeKey){
        delete cloneData[findTimeKey]
    }
    
    emits('change', filterData)
}

const filterList = ref([])
const filterTagList = ref([])
const initSearch = ref(false)
watch(() => props.source, (newVal) => {

    filterList.value = _.cloneDeep(newVal)

    filterList.value.forEach(item => {
        filterForms[item.bindKey] = item.bindValue ? item.bindValue : ''
        if(item.bindValue){
            initSearch.value = true
        }
        if (item.type === 'select') {

            if (item.multiple) {
                if (!item.bindValue) {
                    filterForms[item.bindKey] = []
                }
                if (item.bindValue) {
                    item.labelValueList = item.options.filter(option => item.bindValue.includes(item.fieldNames && item.fieldNames.value ? (option[item.fieldNames.value]) : option.value))
                }
            } else {
                if (item.bindValue) {
                    item.labelValueList = item.options.filter(option => (item.fieldNames && item.fieldNames.value ? (option[item.fieldNames.value]) : option.value) == item.bindValue)
                }
            }
        }
        if (item.type === 'rangePicker') {
            item.width = item.width ? item.width : (item.showTime ? '350' : '260')
            if (!item.bindValue) {
                filterForms[item.bindKey] = []
            } else {
                filterForms[item.bindKey] = [dayjs(item.bindValue[0]), dayjs(item.bindValue[1])]
                item.timeList = item.bindValue
                filterForms[item.bindKey.split(',')[0].trim()] = item.bindValue[0]
                filterForms[item.bindKey.split(',')[1].trim()] = item.bindValue[1]
            }

        }
        // if(item.type ===)
    })

    filterTagList.value = filterList.value.filter(item => item.bindValue)
    filterList.value = filterList.value.filter(item => !item.bindValue)
    if(initSearch.value){
        handleDealResult()
    }
}, { immediate: true })



const pickOpenFlag = ref(false)
const datePickOpenFlag = ref(false)
const filterListVisible = ref(false)



onMounted(() => {
})

/**
 * 下拉过滤条件选取
 * @param {*} index 
 * @param {*} item 
 */
const handleShowFilterTag = (index, item) => {
    if (item.disabled) return;
    currentFilterItem.value = item
    if (item.type === 'input') {
        if (seachInputRef.value.stateValue) {
            setInputData(seachInputRef.value.stateValue)
            handleChooseOk()
            filterList.value.forEach(item => {
                item.disabled = false
            })
            return;
        }
        seachInputRef.value && seachInputRef.value.focus()
        seachInputRef.value.stateValue = ''
        seachInputPlaceHolder.value = '请输入内容，按回车键结束！'
        closeAllModleBox()
        return;
    }
    showFilterComponentFlag.value = true
    seachInputPlaceHolder.value = ''
    if (item.type === 'rangePicker') {
        rangePickerRef.value && rangePickerRef.value.focus()
        pickOpenFlag.value = true
    }
    if (item.type === 'datePicker') {
        rangeDatePickerRef.value && rangeDatePickerRef.value.focus()
        datePickOpenFlag.value = true
    }
    if (item.type === 'select') {
        nextTick(() => {
            selectRef.value && selectRef.value.focus()
        })
    }
}

/**
 * input输入框完成事件
 */
const handlePressEnter = (e) => {
    if (!currentFilterItem.value || currentFilterItem.value.type != 'input') return;
    setInputData(e.target._value)
    handleChooseOk()
}
const setInputData = (info) => {
    seachInputRef.value && seachInputRef.value.blur()
    seachInputPlaceHolder.value = ''
    currentFilterItem.value.bindValue = info
    filterForms[currentFilterItem.value.bindKey] = info
    seachInputRef.value.stateValue = ''
}
const inputHandle = (e) => {
    setTimeout(() => {
        if (e.target._value) {
            filterList.value.forEach(item => {
                if (item.type !== 'input') {
                    item.disabled = true
                }
            })
        } else {
            filterList.value.forEach(item => {
                item.disabled = false
            })
        }
    }, 0)
}

const delFilterTag = (index, item) => {

    filterTagList.value.splice(index, 1)
    filterList.value.push(item)
    let formValue = filterForms[item.bindKey]
    if (typeof formValue == 'string' || typeof formValue == 'number') {
        filterForms[item.bindKey] = ''
    }
    if (Array.isArray(formValue)) {
        filterForms[item.bindKey] = []
    }
    if (item.type === 'rangePicker') {
        filterForms[item.bindKey.split(',')[0].trim()] = ''
        filterForms[item.bindKey.split(',')[1].trim()] = ''
    }
    item.labelValueList = undefined
    handleHideFilterList()
    handleDealResult('delete', item)
}

const handleScroll = () => {
    let targetLeft = searchInput.value.offsetLeft - container_wrap.value.scrollLeft
    currentTagOffsetLeft.value = targetLeft < 0 ? 0 : targetLeft
}
const handleInputFocus = (e) => {
    let targetLeft = e.target.offsetParent.offsetLeft - container_wrap.value.scrollLeft
    currentTagOffsetLeft.value = targetLeft < 0 ? 0 : targetLeft
    if (!e.target._value) {
        filterList.value.forEach(item => {
            item.disabled = false
        })
    }
    filterListVisible.value = true
}
const handleInputBlur = (e) => {
    if (!e.target._value) {
        currentFilterItem.value = null
        seachInputPlaceHolder.value = ''
    }
}

const handleHideFilterList = () => {
    setTimeout(() => {
        if (currentFilterItem.value && currentFilterItem.value.timeList) {
            filterForms[currentFilterItem.value.bindKey.split(',')[0].trim()] = ''
            filterForms[currentFilterItem.value.bindKey.split(',')[1].trim()] = ''
            filterForms[currentFilterItem.value.bindKey] = []
        }
        closeAllModleBox()
    }, 100)
}


/**
 * select选择完成
 * @param {*} args 
 * @param {*} item 
 */
const selectChange = (args, item) => {
    const chooseItem = args[1]
    if (!item.multiple) { //单选时候的tag展示数据处理
        item.labelValueList = [chooseItem]
        item.component_container_visible = false
        handleChooseOk()
    } else {//多选时候的tag展示数据处理
        item.labelValueList = chooseItem
    }
}
const selectBlur = () => {
    if (!currentFilterItem.value.labelValueList || (currentFilterItem.value.labelValueList && currentFilterItem.value.labelValueList.length < 1)) {
        closeAllModleBox()
        return;
    }
    handleChooseOk()
}
const filterSelectOption = (input, option) => {
    let filedLableName = currentFilterItem.value.fieldNames && currentFilterItem.value.fieldNames.label ? currentFilterItem.value.fieldNames.label : 'label'
    return option[filedLableName].toLowerCase().indexOf(input.toLowerCase()) >= 0;
}


/**
 * DatePicker绑定的key字段,赋值
 */
const handlePickChange = (arg, item) => {
    if (arg[0]) {
        filterForms[item.bindKey.split(',')[0].trim()] = arg[1][0]
        filterForms[item.bindKey.split(',')[1].trim()] = arg[1][1]
        item.timeList = arg[1]
    }
}

const handleDatePickChange = (arg, item)=>{
    filterForms[item.bindKey] = arg[0]
    item.bindValue = arg[1]
    console.log(12321,filterForms)
}

/**
 * 关闭DatePicker弹框，展示选取数据
 */
const handlePickOk = () => {

    handleChooseOk()
}

const handleChooseOk = () => {
    let findIndex = filterList.value.findIndex(item => item.bindKey === currentFilterItem.value.bindKey)
    filterList.value.splice(findIndex, 1)
    filterTagList.value.push(currentFilterItem.value)
    handleDealResult('add')
    currentFilterItem.value = null
    closeAllModleBox()
}

//关闭下拉和过滤组件弹框
const closeAllModleBox = () => {
    showFilterComponentFlag.value = false
    filterListVisible.value = false

}


const handleClickOutSide = (index) => {
    // filterTagList.value.forEach(item => {
    //     item.component_container_visible = false
    // })
    filterTagList.value[index].component_container_visible = false
}

// const component_container_style = computed(() => {
//     return {
//         left: currentTagOffsetLeft.value + 'px'
//     }
// })

</script>
<style lang='less' scoped>
strong {
    font-weight: normal;
}

.inputWrapper {
    padding: 10px;

    section {
        margin-top: 20px;
        display: flex;
        justify-content: end;

        button {
            margin-left: 15px;
        }
    }
}

.category-search {
    position: relative;
    //width: 800px;
    height: 32px;
    display: flex;
    border-radius: 2px;
    padding-left: 4px;
    background: transparent;
    border: 1px solid #d7d8da;
    z-index: 20;
    margin-bottom: 16px;

    .search_icon {
        display: flex;
        width: 30px;
        padding: 0 6px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-right: 1px solid #aaa;
    }

    .component_container_wrap {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 20;
    }

    .search-container {
        position: relative;
        flex: 1;

        .container_wrap {
            min-width: 300x;
            overflow-x: auto;
            overflow-y: hidden;

            &>div {
                display: flex;
                flex: 1;
                min-width: 300px;
            }
        }

        .component_container {
            position: absolute;
            top: 34px;
            background: #fff;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
            border-radius: 2px;
        }

        .filterComponent {
            left: 202px;
        }

        .filterList {
            width: 200px;
            min-height: 100px;
            max-height: 280px;
            padding: 10px 0;
            overflow-y: auto;

            li {
                position: relative;
                width: 90%;
                margin: 0 auto;
                height: 32px;
                line-height: 32px;
                padding: 0 10px;
                cursor: pointer;
                border-radius: 2px;

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }

                .arrow {
                    position: absolute;
                    right: 2px;
                    top: 10px;
                }

                &.disabled {
                    cursor: not-allowed;
                    color: #ccc;
                }

                &.no_more {

                    p {
                        text-align: center;
                        color: #aaa;
                    }
                }
            }
        }

        .search-action {
            position: absolute;
            right: 0;
            top: 0;
        }

        .search-input {
            position: relative;
            flex: 1;
            width: 100%;
            height: 100%;

            input {
                border: none !important;

                // &:focus {
                //     border: none !important;
                //     box-shadow: none;
                // }
            }
        }

        ul {
            display: flex;
            font-size: 14px;
            align-items: center;

            li {
                position: relative;
                background: #e9edfa;
                height: 20px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                padding-right: 35px;
                padding-left: 10px;
                margin: 0 5px;
                cursor: pointer;
                margin-right: 8px;

                &:hover {
                    box-shadow: 0 0 2px 2px rgba(100, 100, 200, 0.2);
                }

                .block-item {
                    position: relative;
                    display: flex;

                    &>span {
                        color: #888;
                    }

                    .hide_item {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        opacity: 0;
                        z-index: 1;
                    }

                    .lableList {}
                }

                .close-icon {
                    position: absolute;
                    right: 8px;
                    top: 3px;
                    cursor: pointer;
                    color: #aaa;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }
}
</style>
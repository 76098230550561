<template>
  <div class="project-list">
    <div class="category">
      <ModuleList
        v-model:selectValue="selectTypeCode"
        :list="typeList"
        @change="changeModule"
      />
    </div>
    <div class="content">
      <div class="project-list-header">
        <span>{{ detail.subjectName }}</span>
        <a-date-picker @change="dateChange" style="width: 90px;" v-model:value="year" picker="year" />
      </div>
      <div class="base_info block_item">
        <h2>目标概况</h2>
        <div class="content_info">
          <section>
            <label>净碳排：</label>
            <span>{{ detail.netCarbonEmissions }}</span>
            <span>&nbsp;&nbsp;tCO2e</span>
          </section>
          <section>
            <label>范围一：</label>
            <span>{{ detail.range1 }}</span>
            <span>&nbsp;&nbsp;tCO2e</span>
          </section>
          <section>
            <label>范围二：</label>
            <span>{{ detail.range2 }}</span>
            <span>&nbsp;&nbsp;tCO2e</span>
          </section>
          <section>
            <label>范围三：</label>
            <span>{{ detail.range3 }}</span>
            <span>&nbsp;&nbsp;tCO2e</span>
          </section>
          <section>
            <label>碳清除：</label>
            <span>{{ detail.carbonRemoval }}</span>
            <span>&nbsp;&nbsp;tCO2e</span>
          </section>
          <section class="editIcon">
            <label>正常用量范围：</label>
            <span>{{ detail.emissionLowerLimit?detail.emissionLowerLimit+'%':'' }}~{{ detail.emissionUpperLimit?detail.emissionUpperLimit+'%':'' }}</span>
            <form-outlined @click="rangeModalVisible = true"/>
          </section>
        </div>
      </div>
      <div class="list" >
        <div class="block_item list_block">
          <div class="navs">
            <h2>计划明细</h2>
            <a-radio-group v-model:value="rangeId" style="margin-bottom: 16px" @change="handleRange">
              <a-radio-button value="2">范围一</a-radio-button>
              <a-radio-button value="3">范围二</a-radio-button>
              <a-radio-button value="4">范围三</a-radio-button>
              <a-radio-button value="1">碳清除</a-radio-button>
            </a-radio-group>
          </div>
          <a-button type="primary" @click="handleAdd">添加</a-button>
        </div> 
        <BasicTable ref="tableRef" v-bind="tableConfig" >
        </BasicTable>
      </div>
    </div>
    <editModal
      v-if="addModalVisible"
      v-model:visible="addModalVisible"
      :info="currentRow"
      :id="targetId"
      :rangeId="rangeId"
      @success="editSuccess"
    ></editModal>
    <rangeModal
      v-if="rangeModalVisible"
      v-model:visible="rangeModalVisible"
      :id="selectTypeCode"
      :year="year"
      :info="detail"
      @success="editSuccess"
    ></rangeModal>
  </div>
</template>
<script setup>
import { ref, onActivated, reactive, nextTick } from "vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import ModuleList from './components/moduleLeft.vue'
import editModal from "./components/editModal";
import rangeModal from "./components/rangeModal";
import dayjs from "dayjs";
import { Modal, message } from "ant-design-vue";

onActivated(() => {
  initOptions();
});
const year = ref(dayjs(new Date()))
const rangeId = ref('2')
const targetId = ref(null)

let selectTypeCode = ref('');
const typeList = ref([]);
const initOptions = async () => {
  apiCarbonInfo.panchaList().then((res) => {
    if(res.result){
      //typeList.value = convertToTree(res.result)
      typeList.value = res.result.map((item)=>({
        code: item.id,
        name: item.subjectName,
      }));
      
      if(res.result[0]){
        selectTypeCode.value = res.result[0].id;
        console.log(1233333, res.result[0])
        getTargetDetail()
      }
    }
  });
};

const changeModule = ()=>{
  rangeId.value = '2'
  getTargetDetail()
}

const handleRange = ()=>{
  getTargetList()
}

const detail = ref({});
const getTargetDetail = async()=>{
  let res = await apiCarbonInfo.targetDetail({
    subjectId: selectTypeCode.value,
    year: dayjs(year.value).format('YYYY'),
  });
  if(res.result){
    targetId.value = res.result.id;
    tableRef.value.dataSourceRef = res.result.detailVOList || [];
    detail.value = res.result || {}
  }
}

const getTargetList = ()=>{
  apiCarbonInfo.targetList({
    targetId: targetId.value,
    rangeId: rangeId.value,
  }).then((res)=>{
    tableRef.value.dataSourceRef = res.result || [];
  })
}

const dateChange = ()=>{
  initData()
}

const addModalVisible = ref(false)
const currentRow = ref(null)
const editSuccess = ()=>{
  addModalVisible.value = false;
  rangeModalVisible.value = false;
  initData()
}
const initData = async()=>{
  await getTargetDetail()
  nextTick(()=>{
    tableRef.value.dataSourceRef = []
    getTargetList();
  })
}
const handleAdd = ()=>{
  addModalVisible.value = true;
  currentRow.value = null;
}


const rangeModalVisible = ref(false)



const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: false,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "类别",
      type: "text",
      key: "classificationName",
      width:350,
      formatter(val, item){
        return `${item.classificationName}/${item.subitemName}`
      }
    },
    {
      title: "活动设施",
      type: "text",
      key: "activityName",
    },
    {
      title: "目标水平",
      type: "text",
      key: "activityLevelTargetValue",
    },
    {
      title: "水平单位",
      type: "text",
      key: "factorUnit",
    },
    {
      title: "碳排因子",
      type: "text",
      key: "factorName",
    },
    {
      title: "目标值(kgCO2e)",
      type: "text",
      key: "emissionTargetValue",
    },
  ],
  action: {
    title: "操作",
    width: 120,
    fixed: "right",
    items: [
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDel(row);
        },
      },
    ],
  },
});

const handleEdit = (row)=>{
  addModalVisible.value = true;
  currentRow.value = row;
}

const handleDel = (row)=>{
  Modal.confirm({
    title: "提醒",
    content: "请确认是否删除？",
    async onOk() {
      await apiCarbonInfo.targetRemove(row.id);
      message.success("删除成功");
      initData()
    },
  });

}





</script>
<style lang="less" scoped>
.info-wrapper{
  border-bottom: 1px solid #e5e6e8;
  li{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 14px;
    border-top: 1px solid #e5e6e8;
    cursor: pointer;
    font-size: 14px;
    &::before{
      content: '';
      position: absolute;
      left: 2px;
      top: 20px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: #333;
    }
  }
}
.list_block{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .navs{
    display: flex;
    align-items: center;
    /deep/ .ant-radio-group{
      margin-bottom: 0 !important;
      margin-left: 20px;
    }
  }
}
.block_item{
 
  h2{
    position: relative;
    font-size: 16px;
    padding-left: 10px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 4px;
      height: 16px;
      background: #333;
    }
  }
  .content_info{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 40px;
    padding: 6px 10px 6px 30px;
    border: 1px solid #cfcfcf;
    section{
      width: 33%;
      display: flex;
      align-items: center;
      padding: 10px 0;
    }
  }
}
.project-list {
  display: flex;
  height: 100%;
  gap: 8px;
  .category{
    width: 240px;
    height: 100%;
    background: #fff;
    h2{
      text-align: center;
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #e5e6e8;
      font-size: 14px;
    }
  }
  .content{
    position: relative;
    padding: 10px 20px;
    flex: 1;
    background: #fff;
    .list{
     
      .pagination{
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
    &>span{
      position: relative;
      padding-right: 20px;
      &:after{
        position: absolute;
        content: '';
        width: 2px;
        height: 20px;
        background: #ddd;
        top: 4px;
        right: 10px;
      }
    }
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
.empty{
  margin-top: 150px;
}
.editIcon{
  .anticon-form{
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>

import { defineStore } from "pinia";
import router from "@/router";
import { cloneDeep } from "lodash";
import { isPermissionMenu } from "../../router/routerHelp";

export const useRouteCacheStore = defineStore("routeCache", {
  state: () => ({
    pageTabs: [], // 保存标签button的数组
    cacheViews: [], // 保存需要缓存的数组
  }),
  getters: {
    getPageTabs: (state) => state.pageTabs,
    getCacheViews: (state) => state.cacheViews,
  },
  actions: {
    addPageTab(tabItem) {
      // // 添加标签按钮，如果当前路由已经打开，则不再重复添加
      const inToolbar = this.pageTabs.find(
        (item) => item.path === tabItem.path
      );
      if (!inToolbar) {
        let copyTabItem = cloneDeep(tabItem);
        //处理 path带参数得情况
        if (
          copyTabItem.path.includes("?") &&
          !copyTabItem.path.includes("?newSystemCode") &&
          copyTabItem.meta &&
          copyTabItem.meta.noTab
        ) {
          let isJustParamNotSameIndex = this.pageTabs.findIndex((item) => {
            let old = item.path.substr(0, item.path.indexOf("?"));
            let current = copyTabItem.path.substr(
              0,
              copyTabItem.path.indexOf("?")
            );
            return old === current || current === item.path;
          });
          if (isJustParamNotSameIndex > -1) {
            if (!copyTabItem.path.includes("?_blank")) {
              this.pageTabs[isJustParamNotSameIndex].path = copyTabItem.path;
            } else {
              let hasMoreParam = copyTabItem.path.includes("&");
              if (hasMoreParam) {
                copyTabItem.path = copyTabItem.path.replace(
                  /(\?_blank).*(&)/gi,
                  "?"
                );
              } else {
                copyTabItem.path = copyTabItem.path.replace(
                  /(\?_blank).*/gi,
                  ""
                );
              }
              this.pageTabs[isJustParamNotSameIndex].path = copyTabItem.path;
            }
            return;
          }
        }
        if (!copyTabItem.path.includes("?")) {
          let findOne = this.pageTabs.find(
            (item) => {
              return item.path.substring(0, item.path.indexOf('?'))  === copyTabItem.path
            }
          );
          if(findOne){
            return;
          }
        }

        //处理系统切换
        if (copyTabItem.path.includes("?newSystemCode")) {
          let index = copyTabItem.path.indexOf("?newSystemCode");
          copyTabItem.path = copyTabItem.path.substr(0, index);
        }
        if (copyTabItem.path.includes("?_blank")) {
          let hasMoreParam = copyTabItem.path.includes("&");
          if (hasMoreParam) {
            copyTabItem.path = copyTabItem.path.replace(
              /(\?_blank).*(&)/gi,
              "?"
            );
          } else {
            copyTabItem.path = copyTabItem.path.replace(/(\?_blank).*/gi, "");
            const hasOne = this.pageTabs.find(
              (item) => item.path === copyTabItem.path
            );
            if(hasOne)return;
          }
        }
        this.pageTabs.push({
          ...copyTabItem,
        });
        this.cacheViews.push(copyTabItem.componentName);
      }
    },
    getPathParam(path) {
      let query = path.substring(path.indexOf("?") + 1);
      let vars = query.split("&");
      let obj = {};
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        obj[pair[0]] = pair[1];
      }
      return obj;
    },
    deletePageTab(tabItem, index) {
      const { fullPath } = router.currentRoute.value;
      const isActive = fullPath === tabItem.path;
      const len = this.pageTabs.length;
      this.pageTabs.splice(index, 1);
      this.cacheViews.splice(index, 1);
      if (isActive) {
        console.log(334, this.pageTabs, index);

        if (index > 0) {
          let path = this.pageTabs[index - 1].path
          let query = this.getPathParam(path);
          if(path.includes('/custom/')){
            router.push(path)
          }else{
            router.push({
              path: path,
              query,
            });
          }
        } else {
          if (len > 1) {
            let path = this.pageTabs[0].path
            let query = this.getPathParam(path);
            if(path.includes('/custom/')){
              router.push(path)
            }else{
              router.push({
                path: path,
                query,
              });
            }
          }
        }
      }
    },
    //刷新菜单的时候，过滤掉已经没有菜单的tab
    resetWithMenu(menus) {
      console.log(445, this.pageTabs)
      let alivePages = cloneDeep(this.pageTabs)
        .map((tab) => ({
          ...tab,
          alive: isPermissionMenu(tab, menus, null, true),
        }))
        .filter((item) => item.alive);
      this.pageTabs = alivePages;
      console.log(456, alivePages)
      this.cacheViews = alivePages.map((item) => item.componentName);
    },
    //切换系统的时候，删除当前系统的tab
    resetWithSystem(topSystemPathPrefix) {
      let alivePages = cloneDeep(this.pageTabs).filter(
        (item) => !item.path.includes(topSystemPathPrefix)
      );
      this.pageTabs = alivePages;
      this.cacheViews = alivePages.map((item) => item.componentName);
    },
    resetAll() {
      this.pageTabs = [];
      this.cacheViews = [];
    },
    setAll(pageTabs, cacheViews) {
      this.pageTabs = pageTabs;
      this.cacheViews = cacheViews;
    },
    async setPageTabs(routeItem) {
      console.log(345, routeItem)
      const { fullPath, meta, matched } = routeItem;
      let currentComponent = matched[matched.length - 1].components;
      let componentName = "";
      if (currentComponent.default.__name) {
        componentName =
          currentComponent.default.name || currentComponent.default.__name;
      } else {
        const res = await currentComponent.default();
        componentName = res.default.name || res.default.__name;
      }

      const tabItem = {
        path: fullPath,
        title: meta?.title ?? "",
        componentName: componentName,
        meta: meta,
      };
      this.addPageTab(tabItem);
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: sessionStorage,
        paths: ["pageTabs", "cacheViews"],
      },
    ],
  },
});

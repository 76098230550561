import http from "../utils/http";

// 碳咨询模块
export const apiCarbonInfo = {
    page(data) {
      return http.request({
        url: "/cemp-carbon/carbon-information/page",
        method: "post",
        data
      });
    },
    add(data) {
        return http.request({
          url: "/cemp-carbon/carbon-information/save",
          method: "post",
          data
        });
    },
    delete(params) {
        return http.request({
          url: "/cemp-carbon/carbon-information/delete",
          method: "delete",
          params
        });
    },
    detail(params) {
        return http.request({
          url: "/cemp-carbon/carbon-information/content",
          method: "get",
          params
        });
    },
    cancelPublish(params) {
        return http.request({
          url: "/cemp-carbon/carbon-information/cancel-publish",
          method: "put",
          params
        });
    },
    publish(params) {
        return http.request({
          url: "/cemp-carbon/carbon-information/publish",
          method: "put",
          params
        });
    },
    contentSave(data) {
        return http.request({
          url: "/cemp-carbon/carbon-information/content-save",
          method: "post",
          data
        });
    },
    viewPage(data) {
      return http.request({
        url: "/cemp-carbon/carbon-information/view-page",
        method: "post",
        data
      });
    },
    viewDetail(params) {
      return http.request({
        url: "/cemp-carbon/carbon-information/view-content",
        method: "get",
        params
      });
    },
    viewTypeTree() {
      return http.request({
        url: "/cemp-carbon/carbon-information/content-type-tree",
        method: "get"
      });
    },
    //碳排子分类-分页列表
    subTypePage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-subitem/page",
        method: "get",
        params
      });
    },
     //碳排子分类-option
     subTypeList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-subitem/list",
        method: "get",
        params
      });
    },
    //碳排排放源-分页列表
    sourcePage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-source/page",
        method: "get",
        params
      });
    },
     //活动设施-分页列表
    activityPage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-activity/page",
        method: "get",
        params
      });
    },
    //碳排因子-分页列表
    yinziPage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-factor/page",
        method: "get",
        params
      });
    },
    //碳排因子-option
    yinziList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-factor/list",
        method: "get",
        params
      });
    },
    //消除因子-分页列表
    xiaoyinziPage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-purge-factor/page",
        method: "get",
        params
      });
    },
     //消除因子-分页列表
     xiaoyinziList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-purge-factor/list",
        method: "get",
        params
      });
    },
    //盘查主体-分页列表
    panchaPage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-inventory-subject/page",
        method: "get",
        params
      });
    },
    //盘查主体-option
    panchaList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-inventory-subject/list",
        method: "get",
        params
      });
    },
    //碳排分类-option
    typeList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-item/list",
        method: "get",
        params
      });
    },
    //碳排范围-option
    rangeList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-range/list",
        method: "get",
        params
      });
    },
     //设施活动-option
    activityList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-emission-activity/list",
        method: "get",
        params
      });
    },
    panchaSave(data) {
      return http.request({
        url: "/cemp-carbon/carbon-inventory-subject/save",
        method: "post",
        data
      });
    },
    panchaRemove(id) {
      return http.request({
        url: `/cemp-carbon/carbon-inventory-subject/remove?id=${id}`,
        method: "post",
      });
    },

    //核查报告
    reportPage(params) {
      return http.request({
        url: "/cemp-carbon/carbon-verification-report/page",
        method: "get",
        params
      });
    },
    //核查报告-删除
    reportRemove(id) {
      return http.request({
        url: `/cemp-carbon/carbon-verification-report/remove?id=${id}`,
        method: "post",
      });
    },
    //核查报告-新增
    reportSave(data) {
      return http.request({
        url: "/cemp-carbon/carbon-verification-report/save",
        method: "post",
        data
      });
    },

    //碳目标管理
    targetDetail(params) {
      return http.request({
        url: "/cemp-carbon/carbon-target/detail",
        method: "get",
        params
      });
    },
    //碳目标管理-list
    targetList(params) {
      return http.request({
        url: "/cemp-carbon/carbon-target-detail/list",
        method: "get",
        params
      });
    },
    //碳目标管理-新增
    targetSave(data) {
      return http.request({
        url: "/cemp-carbon/carbon-target-detail/save",
        method: "post",
        data
      });
    },
    //碳目标管理-目标值
    targetValueDeal(data) {
      return http.request({
        url: "/cemp-carbon/carbon-target-detail/calc-emission-target-value",
        method: "post",
        data
      });
    },
    //碳目标管理-删除
    targetRemove(id) {
      return http.request({
        url: `/cemp-carbon/carbon-target-detail/remove?id=${id}`,
        method: "post",
      });
    },
    //碳目标管理-修改上下限
    limitUpdate(data) {
      return http.request({
        url: "/cemp-carbon/carbon-target/limit/update",
        method: "post",
        data
      });
    },
}
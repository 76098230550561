<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button v-show="currentTab == 2" type="primary" @click="handleAdd">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotTitle="{ row }">
        <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.id}`,
                state: { tabName: row.id },
              }"
              >{{ row.title }}</router-link
            >
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiCarbonInfo } from "@/api/carbon.js";
import editModal from "./components/editModal";
import { Modal, message } from "ant-design-vue";
import { apiDictListByCode, apiFileUpload } from "@/api/common";
import { downLoadFile } from "@/utils/excel";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
  initOptions();
});

const currentTab = ref('2');
const messageTypeMap = ref({});
const messageStatus = ref({});
const initOptions = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageTypeMap.value = _map;
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};

const filterSource = ref([
  {
    label: "碳排年份",
    type: "datePicker",
    bindKey: "year",
    picker:'year',
    format:'YYYY',
    placeholder: "请输入",
  },
  {
    label: "核查机构关键字",
    type: "input",
    bindKey: "verificationAgency",
    placeholder: "请输入",
  },
]);

let searchData = {};
const search = (data) => {
  let copyData = JSON.parse(JSON.stringify(data))
  console.log(123, copyData, data)
  if(copyData.year){
    copyData.year = data.year.$y
  }
  
  searchData = copyData;
  console.log(222,data)
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "id",
      width: 120,
    },
    {
      title: "碳排主体",
      type: "text",
      key: "subjectName",
    },
    {
      title: "碳排年份",
      type: "text",
      key: "year",
    },
    {
      title: "核查机构",
      type: "text",
      key: "verificationAgency",
    },
    {
      title: "核查时间",
      type: "text",
      key: "checkDate",
    },
    {
      title: "报告摘要",
      type: "text",
      key: "reportSummary",
    },
    {
      title: "核查报告",
      type: "text",
      key: "name",
    },
  ],
  action: {
    title: "操作",
    width: 120,
    fixed: "right",
    items: [
      {
        icon: "VerticalAlignBottomOutlined",
        iconTip: "下载",
        type: "icon",
        visibleFn: (row) => row.reportUrl,
        onClick: (row) => {
          handleDown(row);
        },
      },
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDel(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiCarbonInfo.reportPage(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);
const handleAdd = () => {
  addModalVisible.value = true;
  currentRow.value = null;
};


const handleDel = async ({ id }) => {
  Modal.confirm({
    title: "提醒",
    content: "请确认是否删除？",
    async onOk() {
      await apiCarbonInfo.reportRemove(id);
      message.success("删除成功");
      initTableData();
    },
  });
};

const handleEdit = (row) => {
  addModalVisible.value = true;
  console.log(234, row);
  currentRow.value = row;
};

const handleDown = (row)=>{
  let {blob} = apiFileUpload.down(row.reportUrl)
  downLoadFile(blob, decodeURI(row.name));
  message.success("已下载");
}

const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  20px 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>

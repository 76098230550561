<template>
  <div class="article_edit">
      <div class="top">
          <section class="info">
              <section class="title">
                <h1 v-if="!editTitle">{{ info.title }}</h1>
                <a-input style="width: 800px;" v-if="editTitle" v-model:value="new_title"/>
                <form-outlined @click="dealEditTitle" v-if="!readonly && !editTitle"/>
                <a-button @click="dealCancel" v-if="editTitle" size="small">取消</a-button>
                <a-button @click="dealSaveTitle" v-if="editTitle" type="primary" size="small">确定</a-button>
              </section>  
              <div>
                  <span>{{ info.contentTypeName }}</span>
                  <span>{{ info.articleTypeName }}</span>
                  <span>{{ dayjs(info.createTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </div>
          </section>
          <section class="action">
              <a-button type="primary" @click="dealSaveContent">保存</a-button>
              <a-button v-if="info.articleStatus === '01'" @click="dealPublish">发布</a-button>
              <a-button v-else @click="cancelPublish">取消发布</a-button>
          </section>
      </div>
      <div class="content" >
        <BasicEditor v-model:content="content" :readonly="readonly" :code="info.id" />
      </div>
  </div>
</template>
<script setup>
import { onActivated, ref } from 'vue';
import { BasicEditor } from '@/components/basic/editor'
import { apiCarbonInfo } from "@/api/carbon.js";
import dayjs from "dayjs";
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { onBeforeRouteUpdate } from "vue-router";

const content = ref('');
const readonly = ref(false);
const editTitle = ref(false);
const new_title = ref('');
const info = ref({});
const route = useRoute();
const _id = ref('')

const initDetail = ()=>{
    apiCarbonInfo.detail({
        id: _id.value
    }).then(res=>{
        if(res.result){
            info.value = res.result || {};
            content.value = res.result.content;
            readonly.value = res.result.articleStatus == '02';
            new_title.value = res.result.title;
        }
    })
};

//保存
const dealSaveContent = ()=>{
    apiCarbonInfo.contentSave({
        id: _id.value,
        content: content.value
    }).then(()=>{
        message.success('保存成功！');
        initDetail();
    })
}

//发布
const dealPublish = ()=>{
    apiCarbonInfo.publish({
        id: _id.value
    }).then(()=>{
        message.success('发布成功！');
        readonly.value = true;
        editTitle.value = false;
        initDetail();
    })
};

//取消发布
const cancelPublish = ()=>{
    apiCarbonInfo.cancelPublish({
        id: _id.value
    }).then(()=>{
        message.success('已取消发布！');
        readonly.value = false;
        initDetail();
    })
};
const dealEditTitle = ()=>{
    editTitle.value = true;
}
const dealSaveTitle = ()=>{
    editTitle.value = false;
    apiCarbonInfo.add({
        id: info.value.id,
        title: new_title.value,
        contentType: info.value.contentType,
        articleType: info.value.articleType,
    }).then(()=>{
        initDetail();
    })
};

const dealCancel = ()=>{
    editTitle.value = false;
    new_title.value = info.value.title;
}


onBeforeRouteUpdate((to, from) => {
  if (to.path != from.path) {
    _id.value = to.params?.id ?? "";
    info.value.id = -1

    initDetail();
  }
});

onActivated(() => {
    _id.value = route.params?.id ?? "";
    info.value.id = -1
    initDetail();
});
</script>
<style lang='less' scoped>
.article_edit{
  padding: 20px;
  background: #fff;
  .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      h1{
          font-size: 16px;
          font-weight: bold;
          margin-right: 10px;
      }
      .info{
        width: 80%;
      }
      .title{
        display: flex;
        align-items: center;
      }
      div{
        span{
            display: inline-block;
            color: #888;
            height: 12px;
            line-height: 12px;
            font-size: 12px;
            border-right: 2px solid #aaa;
            padding-right: 8px;
            padding-left: 8px;
            &:last-child{
                border-right: none;
            }
            &:first-child{
                padding-left: 0;
            }
        }
      }
     
      button{
          margin-left: 16px;
      }
  }
}
</style>
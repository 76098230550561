import router from "@/router";
import _ from "lodash";

export const getComponentName = () => {
  let currentMatched = _.cloneDeep(router.currentRoute.value.matched);
  let currentComponent =
    currentMatched[currentMatched.length - 1]?.components.default ?? {};
  let componentName = currentComponent.name || currentComponent.__name;
  return componentName;
};
export function getBaseUrl(){
  let url = window.location.origin;
  let _url = window.location.host;
  let VUE_APP_API_BASE_URL = '';
  let VUE_APP_MQTT_BASE_URL = '';


  
  if(url.includes('https')){
    VUE_APP_MQTT_BASE_URL = `wss://${_url}/ws/mqtt`
  }else{
    VUE_APP_MQTT_BASE_URL = `ws://${_url}/ws/mqtt`
  }
  VUE_APP_API_BASE_URL = `${url}/api`;

  if(url.includes('localhost')){
    //本地调试开发，拿开发环境
    VUE_APP_API_BASE_URL = `http://172.20.1.94:8080/api`;
    VUE_APP_MQTT_BASE_URL = `ws://172.20.1.94:8080/ws/mqtt`
  }
  return {
    VUE_APP_API_BASE_URL,
    VUE_APP_MQTT_BASE_URL
  }
}
// 生成hashcode
export function getUuidCode() {
  //64长度
  const len = 64;
  //16进制
  let radix = 16;
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}

export function filterArrayDeep(arr, value, valueKey = "id") {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (item[valueKey] === value) return item;
    if (item?.children?.length) {
      const _item = filterArrayDeep(item.children, value, valueKey);
      if (_item) return _item;
    }
  }
}

export function formatTreeSelectable(arr, key, forward = true) {
  arr.forEach((item) => {
    item.selectable = forward ? item[key] : !item[key];
    if (item.children?.length) {
      formatTreeSelectable(item.children, key, forward);
    }
  });
}

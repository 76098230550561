<template>
  <a-form
    class="basic-form"
    ref="formRef"
    name="form_model"
    :labelCol="labelCol"
    :layout="layout"
    :labelAlign="labelAlign"
    :model="formData"
  >
    <a-row :gutter="gutter">
      <template v-for="item in formItems" :key="item.prop">
        <a-col :span="item.span || 24" v-if="!item.hide">
          <a-form-item
            :label="item.label"
            :name="item.prop"
            :colon="item.colon"
            :rules="[
              {
                required: item.required,
                message: item.requiredMessage,
                trigger: item.requiredTrigger,
              },
              ...(item.rules || []),
            ]"
          >
            <a-spin :spinning="item.loading || false">
              <template v-if="item.slot">
                <slot :name="item.prop" :item="item" :form="formData"></slot>
              </template>
              <template v-else-if="item.type === 'text'">
                <span :class="item.textClass" :style="item.textStyle">{{
                  item.text || formData[item.textProp || item.prop]
                }}</span>
              </template>
              <template v-else>
                <a-input
                  v-if="item.type === 'input'"
                  :placeholder="item.placeholder || '请输入'"
                  allowClear
                  :type="item.inputType"
                  :prefix="item.prefix"
                  :suffix="item.suffix"
                  :maxlength="item.maxlength"
                  :value="formData[item.prop]"
                  :disabled="item.disabled || disabled"
                  @change="(e) => onChange(item, e.target.value)"
                >
                </a-input>
                <a-input-number
                  v-else-if="item.type === 'inputNumber'"
                  :placeholder="item.placeholder || '请输入'"
                  :allowClear="item.allowClear"
                  :controls="item.controls"
                  :prefix="item.prefix"
                  :min="item.min"
                  :max="item.max"
                  :addon-after="item.after"
                  :value="formData[item.prop]"
                  :disabled="item.disabled || disabled"
                  @change="(value) => onChange(item, value)"
                >
                </a-input-number>
                <a-input-password
                  v-if="item.type === 'password'"
                  :placeholder="item.placeholder || '请输入'"
                  allowClear
                  :value="formData[item.prop]"
                  :disabled="item.disabled || disabled"
                  @change="(e) => onChange(item, e.target.value)"
                >
                </a-input-password>
                <a-textarea
                  v-else-if="item.type === 'textarea'"
                  placeholder="请输入"
                  allowClear
                  :maxlength="item.maxlength"
                  :value="formData[item.prop]"
                  :rows="4"
                  :disabled="item.disabled || disabled"
                  @change="(e) => onChange(item, e.target.value)"
                />
                <a-select
                  v-else-if="item.type === 'select'"
                  :value="formData[item.prop]"
                  placeholder="请选择"
                  allowClear
                  showSearch
                  :mode="item.mode"
                  :maxTagCount="item.maxTagCount || 2"
                  :options="item.options"
                  :fieldNames="item.fieldNames"
                  :filterOption="
                    (value, option) =>
                      filterOption(
                        value,
                        option,
                        item.fieldNames ? item.fieldNames.label : 'label'
                      )
                  "
                  :disabled="item.disabled || disabled"
                  @change="(e) => onChange(item, e)"
                >
                </a-select>
                <a-switch
                  v-else-if="item.type === 'switch'"
                  :checked="formData[item.prop]"
                  :checkedValue="item.checkedValue"
                  :unCheckedValue="item.unCheckedValue"
                  :disabled="item.disabled || disabled"
                  @change="(checked) => onChange(item, checked)"
                />
                <a-radio-group
                  v-else-if="item.type === 'radio'"
                  name="radioGroup"
                  :value="formData[item.prop]"
                  :disabled="item.disabled || disabled"
                  @change="(e) => onChange(item, e.target.value)"
                >
                  <a-radio
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </a-radio>
                </a-radio-group>
                <a-checkbox-group
                  v-else-if="item.type === 'checkboxGroup'"
                  :value="formData[item.prop]"
                  :options="item.options"
                  :disabled="item.disabled || disabled"
                  @change="(checkedValue) => onChange(item, checkedValue)"
                />
                <a-cascader
                  v-else-if="item.type === 'cascader'"
                  placeholder="请选择"
                  :options="item.options"
                  :value="formData[item.prop]"
                  :disabled="item.disabled || disabled"
                  @change="(value) => onChange(item, value)"
                />
                <a-tree-select
                  v-else-if="item.type === 'treeSelect'"
                  placeholder="请选择"
                  allowClear
                  treeDefaultExpandAll
                  :showSearch="item.showSearch"
                  :treeNodeFilterProp="item.treeNodeFilterProp"
                  :multiple="item.multiple"
                  :maxTagCount="item.maxTagCount || 2"
                  :disabled="item.disabled || disabled"
                  :value="formData[item.prop]"
                  :tree-data="item.options"
                  :field-names="item.fieldNames"
                  :tree-checkable="item.treeCheckable"
                  :tree-check-strictly="item.treeCheckStrictly"
                  :showCheckedStrategy="
                    showCheckedStrategyMap[item.showCheckedStrategy]
                  "
                  @change="(value) => onChange(item, value)"
                />
                <a-date-picker
                  v-else-if="item.type === 'datePicker'"
                  :format="item.format"
                  :valueFormat="item.valueFormat || item.format"
                  :picker="item.picker"
                  :showTime="item.showTime"
                  :disabled="item.disabled || disabled"
                  :value="formData[item.prop]"
                  @change="(date, dateString) => onChange(item, dateString)"
                />
                <a-range-picker
                  v-else-if="item.type === 'rangePicker'"
                  :format="item.format"
                  :valueFormat="item.valueFormat || item.format"
                  :showTime="item.showTime"
                  :picker="item.picker"
                  :disabled="item.disabled || disabled"
                  :value="formData[item.prop]"
                  @change="(dates, dateString) => onChange(item, dateString)"
                />
                <template v-else-if="item.type === 'tree'">
                  <div class="tree-box">
                    <a-tree
                      v-if="item.options?.length"
                      defaultExpandAll
                      checkable
                      :checkStrictly="item.checkStrictly"
                      :disabled="item.disabled || disabled"
                      :checkedKeys="formData[item.prop]"
                      :field-names="item.fieldNames"
                      :tree-data="item.options"
                      @check="(value) => onChange(item, value)"
                    />
                    <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
                  </div>
                </template>
                <template v-else-if="item.type === 'calculateDevice'">
                  <CalculateDevice
                    :value="formData[item.prop]"
                    :deviceOptions="item.options"
                    :disabled="item.disabled || disabled"
                    @change="(value) => onChange(item, value)"
                  ></CalculateDevice>
                </template>
                <template v-else-if="item.type === 'timeRangeList'">
                  <TimeRangeList
                    :value="formData[item.prop]"
                    :disabled="item.disabled || disabled"
                    @change="(value) => onChange(item, value)"
                  ></TimeRangeList>
                </template>
                <template v-else-if="item.type === 'addressCascader'">
                  <AddressCascader
                    :value="formData[item.prop]"
                    :disabled="item.disabled || disabled"
                    @change="(value) => onChange(item, value)"
                  ></AddressCascader>
                </template>
                <template v-else-if="item.type === 'lonLat'">
                  <LonLat
                    :value="formData[item.prop]"
                    :disabled="item.disabled || disabled"
                    @change="(value) => onChange(item, value)"
                  ></LonLat>
                </template>
                <template v-else-if="item.type === 'icon'">
                  <component
                    :is="$antIcons[formData[item.prop]]"
                    :style="{
                      color: item.iconColor,
                      fontSize: item.iconFontSize || '16px',
                    }"
                  />
                </template>
              </template>
            </a-spin>
          </a-form-item>
        </a-col>
      </template>
    </a-row>
  </a-form>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, ref, computed } from "vue";
import CalculateDevice from "./components/CalculateDevice";
import TimeRangeList from "./components/TimeRangeList";
import LonLat from "./components/LonLat";
import { TreeSelect, Empty } from "ant-design-vue";
const showCheckedStrategyMap = {
  all: TreeSelect.SHOW_ALL,
  parent: TreeSelect.SHOW_PARENT,
  child: TreeSelect.SHOW_CHILD,
};
const props = defineProps({
  formItemsMap: {
    type: Object,
    default: () => ({}),
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: String,
    default: "horizontal", // 'horizontal'|'vertical'|'inline'
  },
  gutter: {
    type: [Number, Object, Array],
    default: 0,
  },
  labelAlign: {
    type: String, // 'left' | 'right'
    default: "right",
  },
  labelCol: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["update:formData", "change"]);
const formRef = ref(null);
defineExpose({
  formRef,
});

const formItems = computed(() => Object.values(props.formItemsMap));

const onChange = (item, value) => {
  const { prop, type, checkStrictly } = item;
  const _data = props.formData;
  if (type === "tree" && checkStrictly) {
    _data[prop] = value.checked;
  } else {
    _data[prop] = value;
  }
  formRef.value.validate(prop);
  emit("update:formData", _data);
  emit("change", { prop, value });
};

const filterOption = (input, option, labelKey) => {
  return option[labelKey].toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>

<style lang="less" scoped>
.basic-form {
  ::v-deep .ant-input-number,
  ::v-deep .ant-picker {
    width: 100%;
  }
  ::v-deep .ant-form-item-control {
    // padding-right: 20px;
  }
  .tree-box {
    min-height: 100px;
    padding: 10px 0;
    border: 1px solid rgba(99, 116, 140, 0.2);
    border-radius: 2px;
  }
}
</style>

import * as mqtt from "mqtt";
import { message } from "ant-design-vue";
import { getBaseUrl } from "@/utils/util";
const reConnectMax = 2;
export class MQTT {
  constructor(topic) {
    this.topic = topic;
    this.url = getBaseUrl().VUE_APP_MQTT_BASE_URL
  }
  //初始化链接mqtt
  init(ignoreReconnectLimit) {
    const options = {
      username: "admin",
      password: "landleaf@123.com",
      clean: true, // 保留会话,
      connectTimeout: 8000, //超时时间
      reconnectPeriod: 4000, // 重连时间间隔
      clientId: "s_" + getUuidCode(),
    };
    this.retryTimes = 0;
    this.client = mqtt.connect(this.url, options);
    this.client.on("error", (error) => {
      console.log("mqtt error :", error);
    });
    if (!ignoreReconnectLimit) {
      this.client.on("reconnect", () => {
        this.reconnect();
      });
    }
  }
  reconnect() {
    this.retryTimes += 1;
    message.warning("MQTT 重新连接！");
    if (this.retryTimes > reConnectMax) {
      try {
        this.client.end();
        this.retryTimes = 0;
        message.error("MQTT 连接失败！");
      } catch (error) {
        console.log(error);
      }
    }
  }
  listen(callback) {
    this.client.on("message", (topic, message, packet) => {
      callback(topic, JSON.parse(message), packet);
    });
  }
  //订阅
  subscribe(callback) {
    this.client.on("connect", () => {
      this.client.subscribe(
        this.topic,
        {
          qos: 0,
        },
        callback
      );
    });
  }
  //取消订阅
  unsubscribe() {
    if (this.client && this.client.connected) {
      this.client.unsubscribe(this.topic, (error) => {
        if (!error) {
          console.log("mqtt:", "取消订阅成功！");
        } else {
          console.log("mqtt:", "取消订阅失败！");
        }
      });
    }
  }
  //发布信息
  publish(topic, payload) {
    if (this.client && this.client.connected) {
      this.client.publish(topic, payload, { qos: 0 }, (err) => {
        if (err) {
          console.log("mqtt:发布失败！", err);
        } else {
          console.log("mqtt:发布成功！");
        }
      });
    }
  }
  over() {
    this.client.end();
  }
}

function getUuidCode() {
  //64长度
  const len = 64;
  //16进制
  let radix = 16;
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}
